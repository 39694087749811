import {
  Card,
  FormControlLabel,
  Grid,
  Hidden,
  Switch,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {
  IFilter,
  IConfirmDialog,
  ITimeFilter,
  ICaseDashboard,
  ICaseSearch,
  IReactSelect,
} from "../../vm";
import { useHistory, useLocation } from "react-router-dom";
import Filter from "../common/Filter";
import { ToastContext } from "../common/ToastProvider";
import {
  getCasesFiltersCount, getCompliantNames, getFirmNames, getPlaintiffNames,
} from "../../services/CaseService";
import Loading from "../common/Loading";
import {
  casesStatusDetails,
  styles,
  TIMESHEET_STATUS,
} from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import ToggleFilterSection from "../dashboard/ToggleFilter";
import { getAllCasesDashboard } from "../../services/DashboardService";
import { convertToFixed, numberFormat } from "../../services/UtilService";
import PieChart from "../common/charts/PieChart";
export interface DashboardMainProps extends IConfirmDialog { }

const DashboardMain: React.FC<DashboardMainProps> = ({ confirmDialog }) => {
  const history = useHistory();
  const location = useLocation();
  const fromManagementDashboard = React.useRef(false);
  const managementDashboardId = React.useRef(undefined);
  const [isFilterOpen, setFilterOpen] = React.useState(false);
  const [loadedFilter, setLoadedFilter] = React.useState(
    undefined as ITimeFilter
  );

  let searchObj: any = {};

  const [state, setState] = React.useState({
    search: {
      from: 0,
      size: searchObj?.size ? searchObj.size : 10,
      showOnlyDisapproved: false,
      classificationType: [],
      complaintType: [],
      defMinor: [],
      questionnaireSaved: [],
      requestForDismissal: [],
      plaintiffNames: [],
      plaintiffCounsel: [],
      showPlaintiffNamesCorrected: false,
      showDefMinor: "",
      complaintName: "",
      caseNumber: "",
      startDismissalFilingDate: "",
      endDismissalFilingDate: "",
      statuses: [],
      startDefDismissalEnteredDate: "",
      endDefDismissalEnteredDate: "",
      startQuestionnaireServedDate: "",
      endQuestionnaireServedDate: "",
    },
    isDataLoaded: false,
    casesDashboardData: {}
  } as {
    search: ICaseSearch;
    isDataLoaded: boolean;
    casesDashboardData: ICaseDashboard
  });
  const [colorCode, setColorCode] = React.useState<Array<string>>([
    // "#98AFC7",
    // "#728FCE",
    "#00BFFF",
    "#B0E0E6",
    "#E3E4FA",
    "#AAF0D1",
    "#50C878",
    "#CCFB5D",
    "#C3FDB8",
    "#DBF9DB",
    "#F0FFF0",
    "#F3E5AB",
    "#ECC5C0",
    "#FDD7E4",
    "#FFC0CB",
    "#CCCCFF",
    "#E0B0FF",
    "#DFD3E3",
    "#E9E4D4",
    "#F5F5DC",
    "#FFFDD0",
    "#CCFF99",
    "#CCFFCC",
    "#CCFFFF",
    "#FF99CC",
    "#FFCCCC",
    "#FFFFCC",
    "#F5F5F5",
    "#FFF5EE",
    "#FAAFBE",
    "#F98B88",
    "#FF8C00",
    "#827839",
    "#D4A017",
    "#FFF380",
    "#045D5D",
  ]);
  const [isCasePercentage, setCasePercentage] = React.useState(false);
  const [isFirmPercentage, setFirmPercentage] = React.useState(false);
  const [isPlaintiffPercentage, setPlaintiffPercentage] = React.useState(false);
  const [isMinorPercentage, setMinorPercentage] = React.useState(false);


  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");

  const { showToast } = React.useContext(ToastContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);

  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});

  React.useEffect(() => {
    updateState(location.state);
  }, [location.state]);

  const updateState = (stateFromLocation?: any) => {
    let searchObj: any = {};
    if (stateFromLocation) {
      let searchState = { ...(stateFromLocation as any) };
      if (searchState.status) {
        searchObj.statuses = Array.isArray(searchObj.status)
          ? searchObj.status
          : [searchState.status];
      }
      if (searchState.statuses) {
        searchObj.statuses = searchState.statuses;
      }
      // if (searchState.professionalLevel) {
      //   searchObj.professionalLevel = [searchState.professionalLevel];
      // }
      // if (searchState.categoryCode) {
      //   searchObj.categoryCode = [searchState.categoryCode];
      // }
      if (searchState.startDate) {
        searchObj.startDate = searchState.startDate;
      }
      if (searchState.endDate) {
        searchObj.endDate = searchState.endDate;
      }
      if (searchState.selectedFirm?.length > 0) {
        searchObj.firms = searchState.selectedFirm;
      }
      if (searchState.firms?.length > 0) {
        searchObj.firms = searchState.firms;
      }
      if (searchState.professionalLevel?.length > 0) {
        searchObj.professionalLevel = searchState.professionalLevel;
      }
      if (searchState.categoryCode?.length > 0) {
        searchObj.categoryCode = searchState.categoryCode;
      }
      if (searchState.workType?.length > 0) {
        searchObj.workType = searchState.workType;
      }
      if (searchState.keyword?.length > 0) {
        searchObj.keyword = searchState.keyword;
      }
      if (searchState.min) {
        searchObj.min = searchState.min;
      }
      if (searchState.max) {
        searchObj.max = searchState.max;
      }
      if (searchState.minWordCount) {
        searchObj.minWordCount = searchState.minWordCount;
      }
      if (searchState.maxWordCount) {
        searchObj.maxWordCount = searchState.maxWordCount;
      }
      if (
        searchState.descriptionSearchFilters &&
        searchState.descriptionSearchFilters.length > 0
      ) {
        searchObj.descriptionSearchFilters =
          searchState.descriptionSearchFilters;
      }
      if (searchState.showDisapproved) {
        searchObj.showDisapproved = searchState.showDisapproved;
      }
      if (searchState.persons && searchState.persons.length > 0) {
        searchObj.persons = searchState.persons;
      }
      if (searchState.isFromManagementDashboard) {
        fromManagementDashboard.current = true;
        managementDashboardId.current = searchState.timeManagementDashboardId;
      }
      if (searchState.showMultipleBillingRates) {
        searchObj.showMultipleBillingRates = true;
      }
      if (searchState.approvedBy?.length > 0) {
        searchObj.approvedBy = searchState.approvedBy;
      }
      if (searchState.showOnlyDisapproved) {
        searchObj.showOnlyDisapproved = searchState.showOnlyDisapproved;
      }
      if (searchState.size) {
        searchObj.size = searchState.size;
      }
      if (searchState.noteId) {
        searchObj.noteId = searchState.noteId;
      }
    }
    let obj: any = {
      from: 0,
      size: searchObj?.size ? searchObj.size : 10,
      statuses: searchObj?.statuses ? searchObj.statuses : [],
      firms: searchObj.firms ? searchObj.firms : [],
      persons: searchObj.persons ? searchObj.persons : [],
      // startDate: searchObj.startDate
      //   ? searchObj.startDate
      //   : moment("2017-01-01", "YYYY-MM-DD").subtract(1, "year").toDate(),
      // endDate: searchObj.endDate ? searchObj.endDate : moment().toDate(),
      startDate: searchObj.startDate ? searchObj.startDate : undefined,
      endDate: searchObj.endDate ? searchObj.endDate : undefined,
      sortBy: "",
      sortOrder: "asc",
      keyword: searchObj?.keyword || [],
      min: searchObj.min || "",
      max: searchObj.max || "",
      minWordCount: searchObj.minWordCount || "",
      maxWordCount: searchObj.maxWordCount || "",
      showDisapproved: searchObj.showDisapproved || false,
      descriptionSearchFilters: searchObj.descriptionSearchFilters || [],
      categoryCode: searchObj?.categoryCode ? searchObj.categoryCode : [],
      workType: searchObj?.workType ? searchObj.workType : [],
      professionalLevel: searchObj?.professionalLevel
        ? searchObj.professionalLevel
        : [],
      showMultipleBillingRates: searchObj.showMultipleBillingRates || false,
      approvedBy: searchObj?.approvedBy ? searchObj.approvedBy : [],
      showOnlyDisapproved: searchObj.showOnlyDisapproved || false,
      noteId: searchObj.noteId || "",
    };
    setState({
      ...state,
      search: obj,
    });
    getAllRecordsFromStarting(obj);
  };

  const getAllRecordsFromStarting = async (search?) => {
    setLoading(true);
    await getTimesheetFilters();
    // await getDashboardData(search);
    setLoading(false);
  };

  const getPlainTiffSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
    let result = await getPlaintiffNames(inputValue);
    return result.data.map(x => {
      return {
        label: x.defPlaintiffName,
        value: x.defPlaintiffName,
      }
    });
  }
  const getFirmsSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
    let result = await getFirmNames(inputValue);
    return result.data.map(x => {
      return {
        label: x,
        value: x,
      }
    });
  }
  const getCompliantSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
    let result = await getCompliantNames(inputValue);
    return result.data.map(x => {
      return {
        label: x,
        value: x,
      }
    });
  }

  const getTimesheetFilters = async (
    search?: any,
    changedProperty?: any,
    resetSelectAll?: boolean
  ) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
      if (changedProperty === "load-filters") {
        tempSearch = { ...search, from: 0, size: tempSearch.size };
      }
    }

    delete tempSearch.size;
    delete tempSearch.from;
    setLoading(true);

    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {
        classificationType: [],
        complaintType: [],
        statuses: [],
        defMinor: [],
        questionnaireSaved: [],
        requestForDismissal: [],
      } as any,
    };

    if (isCountsLoaded !== false && changedProperty !== "clear-filters") {
      let filterKeys = [
        "classificationType",
        "complaintType",
        "statuses",
        "defMinor",
        "questionnaireSaved",
        "requestForDismissal"
      ];
      filterKeys.forEach(key => {
        result.data[key] = filterData.find(x => x.name === key)?.items.map(x => {
          return { label: x.label, value: x.name }
        });
      })
    }

    if (isCountsLoaded === false || changedProperty === "clear-filters") {
      result = await getCasesFiltersCount();
    }
    setLoading(false);
    if (result?.isSuccess) {
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty === "clear-filters") {
        let keyParams = {
          classificationType: "classificationType",
          complaintType: "complaintType",
          statuses: "statuses",
          defMinor: "defMinor",
          questionnaireSaved: "questionnaireSaved",
          requestForDismissal: "requestForDismissal",
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            classificationType: [],
            complaintType: [],
            statuses: [],
            defMinor: [],
            questionnaireSaved: [],
            requestForDismissal: []
          }
        );
        setFilterCountsDic(filterCountDic);
      }
      let data = { ...result.data };

      let filterOrder = [
        "classificationType",
        "statuses",
        "showPlaintiffNamesCorrected",
        "plaintiffName",
        "firmName",
        "compliantName",
        "caseNumber",
        "complaintType",
        "defMinor",
        "questionnaireSaved",
        "requestForDismissal",
        "filingDate",
        "dismissalFilingDate",
        "defDismissalEnteredDate",
        "questionnaireServedDate",
      ];
      let customFilters = [
        "filingDate",
        "dismissalFilingDate",
        "defDismissalEnteredDate",
        "questionnaireServedDate",
        "plaintiffName",
        "firmName",
        "compliantName",
        "caseNumber",
      ];
      let filter: IFilter[] = [];

      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "filingDate":
              filter.push({
                header: "Filing Date",
                name: "filingDate",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "filingDate")?.isHidden) ||
                  false,
                items: [
                  {
                    type: "date-range",
                    value: [
                      {
                        startDate: tempSearch.startFilingDate,
                        endDate: tempSearch.endFilingDate,
                        key: "selection",
                      },
                    ],
                    label: "",
                  },
                ],
              });
              break;
            case "dismissalFilingDate":
              filter.push({
                header: "Dismissal Filing Date",
                name: "dismissalFilingDate",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "dismissalFilingDate")?.isHidden) ||
                  false,
                items: [
                  {
                    type: "date-range",
                    value: [
                      {
                        startDate: tempSearch.startDismissalFilingDate,
                        endDate: tempSearch.endDismissalFilingDate,
                        key: "selection",
                      },
                    ],
                    label: "",
                  },
                ],
              });
              break;
            case "defDismissalEnteredDate":
              filter.push({
                header: "Def Dismissal Entered Date",
                name: "defDismissalEnteredDate",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "defDismissalEnteredDate")?.isHidden) ||
                  false,
                items: [
                  {
                    type: "date-range",
                    value: [
                      {
                        startDate: tempSearch.startDefDismissalEnteredDate,
                        endDate: tempSearch.endDefDismissalEnteredDate,
                        key: "selection",
                      },
                    ],
                    label: "",
                  },
                ],
              });
              break;
            case "questionnaireServedDate":
              filter.push({
                header: "Questionnaire Served Date",
                name: "questionnaireServedDate",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "questionnaireServedDate")?.isHidden) ||
                  false,
                items: [
                  {
                    type: "date-range",
                    value: [
                      {
                        startDate: tempSearch.startQuestionnaireServedDate,
                        endDate: tempSearch.endQuestionnaireServedDate,
                        key: "selection",
                      },
                    ],
                    label: "",
                  },
                ],
              });
              break;
            case "plaintiffName":
              filter.push({
                header: "",
                name: "plaintiffName",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Plaintiff Name",
                    type: "dropdown-dynamic",
                    name: filterType,
                    selectedItems: tempSearch.plaintiffNames.map(x => {
                      return { label: x, value: x }
                    }),
                    options: [],
                    asyncCallBackFunction: getPlainTiffSearchName,
                    isMulti: true
                  },
                ],
              });
              break;
            case "firmName":
              filter.push({
                header: "",
                name: "firmName",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Firm Name",
                    type: "dropdown-dynamic",
                    name: filterType,
                    selectedItems: tempSearch.plaintiffCounsel.map(x => {
                      return { label: x, value: x }
                    }),
                    options: [],
                    asyncCallBackFunction: getFirmsSearchName,
                    isMulti: true
                  },
                ],
              });
              break;
            case "compliantName":
              filter.push({
                header: "",
                name: "compliantName",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Compliant Name",
                    type: "dropdown-dynamic",
                    name: filterType,
                    selectedItems: tempSearch.complaintName?.length > 0 ? [{ label: tempSearch.complaintName, value: tempSearch.complaintName }] : [],
                    options: [],
                    asyncCallBackFunction: getCompliantSearchName,
                    isMulti: false
                  },
                ],
              });
              break;
            case "caseNumber":
              filter.push({
                header: "Case Number",
                name: "caseNumber",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "caseNumber").isHidden) ||
                  false,
                items: [{
                  type: "text",
                  value: tempSearch.caseNumber,
                  name: "caseNumber",
                  label: ""
                }],
              });
              break;
            default:
              break;
          }
        } else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "classificationType":
              a.header = "Classification Type";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let classificationType = data["classificationType"] || [];
              classificationType.forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.classificationType?.includes(eds.value) || false,
                  label: eds.label,
                  name: eds.value,
                  type: "checkbox",
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "statuses":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let statuses = data["statuses"] || [];
              statuses.forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses?.includes(eds.value) || false,
                  label: eds.label,
                  name: eds.value,
                  type: "checkbox",
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "showPlaintiffNamesCorrected":
              filter.push({
                className: "disapproved-section",
                header: "",
                name: "showPlaintiffNamesCorrected",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showPlaintiffNamesCorrected")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    isSelected: tempSearch.showPlaintiffNamesCorrected,
                    label: "Show Plaintiff Names Corrected",
                    type: "switch",
                    name: "showPlaintiffNamesCorrected"
                  },
                ],
              });
              break;
            case "complaintType":
              a.header = "Complaint Type";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let complaintType = data["complaintType"] || [];
              complaintType.forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.complaintType?.includes(eds.value) || false,
                  label: eds.label,
                  name: eds.value,
                  type: "checkbox",
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "defMinor":
              a.header = "DEF Minor";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let defMinor = data["defMinor"] || [];
              defMinor.forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.defMinor?.includes(eds.value) || false,
                  label: eds.label,
                  name: eds.value,
                  type: "checkbox",
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "questionnaireSaved":
              a.header = "Questionnaire Saved";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let questionnaireSaved = data["questionnaireSaved"] || [];
              questionnaireSaved.forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.questionnaireSaved?.includes(eds.value) || false,
                  label: eds.label,
                  name: eds.value,
                  type: "checkbox",
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "requestForDismissal":
              a.header = "Request For Dismissal";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let requestForDismissal = data["requestForDismissal"] || [];
              requestForDismissal.forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.requestForDismissal?.includes(eds.value) || false,
                  label: eds.label,
                  name: eds.value,
                  type: "checkbox",
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).map((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses?.includes(eds) || false,
                  label: TIMESHEET_STATUS[eds],
                  name: eds,
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;

            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });


      setCountsLoaded(true);
      setFilterData(filter);

      if (changedProperty !== "add-descriptions") {
        await getDashboardData(tempSearch);
      }
    } else {
      showToast(
        result.message || "Error while fetching cases counts",
        "error"
      );
    }
  };

  const getDashboardData = async (
    search?: any
  ) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
    }

    let searchObj = { ...tempSearch };
    setLoading(true);


    const result = await getAllCasesDashboard(searchObj);
    if (result?.isSuccess) {
      // logic

      setState({
        ...state,
        search: { ...state.search, ...tempSearch },
        casesDashboardData: result.data,
        isDataLoaded:true
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching cases",
        "error"
      );
    }
    setLoading(false);
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    if (changedProperty === "clear-filters") {
      setLoadedFilter(undefined);
      managementDashboardId.current = undefined;
    }
    if (changedProperty === "load-filters") {
      let details = JSON.parse(JSON.stringify(data));
      setLoadedFilter(details);
      await getTimesheetFilters(
        details.filters,
        changedProperty,
        true
      );
    } else {
      let search = { ...state.search };
      search.from = 0;
      let classificationType = data.find((ele) => ele.name === "classificationType")?.items || [];
      search.classificationType = classificationType
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let statuses = data.find((ele) => ele.name === "statuses")?.items || [];
      search.statuses = statuses
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let showPlaintiffNamesCorrected =
        data.find((ele) => ele.name === "showPlaintiffNamesCorrected")?.items || [];
      search.showPlaintiffNamesCorrected = showPlaintiffNamesCorrected.find(
        (ele) => ele.name === "showPlaintiffNamesCorrected"
      ).isSelected;
      let plaintiffName =
        data.find((ele) => ele.name === "plaintiffName")?.items || [];
      search.plaintiffNames = plaintiffName[0].selectedItems.map(x => x.value);
      let firmName =
        data.find((ele) => ele.name === "firmName")?.items || [];
      search.plaintiffCounsel = firmName[0].selectedItems.map(x => x.value);
      let compliantName =
        data.find((ele) => ele.name === "compliantName")?.items || [];
      search.complaintName = compliantName[0].selectedItems.length > 0 ? compliantName[0].selectedItems[0].value : "";
      let caseNumber =
        data.find((ele) => ele.name === "caseNumber")?.items || [];
      search.caseNumber = caseNumber[0].value;


      let filingDate = data.find((ele) => ele.name === "filingDate")?.items || [];
      search.startFilingDate = filingDate[0].value[0].startDate || undefined;
      search.endFilingDate = filingDate[0].value[0].endDate || undefined;

      let dismissalFilingDate = data.find((ele) => ele.name === "dismissalFilingDate")?.items || [];
      search.startDismissalFilingDate = dismissalFilingDate[0].value[0].startDate || undefined;
      search.endDismissalFilingDate = dismissalFilingDate[0].value[0].endDate || undefined;

      let defDismissalEnteredDate = data.find((ele) => ele.name === "defDismissalEnteredDate")?.items || [];
      search.startDefDismissalEnteredDate = defDismissalEnteredDate[0].value[0].startDate || undefined;
      search.endDefDismissalEnteredDate = defDismissalEnteredDate[0].value[0].endDate || undefined;

      let questionnaireServedDate = data.find((ele) => ele.name === "questionnaireServedDate")?.items || [];
      search.startQuestionnaireServedDate = questionnaireServedDate[0].value[0].startDate || undefined;
      search.endQuestionnaireServedDate = questionnaireServedDate[0].value[0].endDate || undefined;

      let complaintType = data.find((ele) => ele.name === "complaintType")?.items || [];
      search.complaintType = complaintType
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let defMinor = data.find((ele) => ele.name === "defMinor")?.items || [];
      search.defMinor = defMinor
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let questionnaireSaved = data.find((ele) => ele.name === "questionnaireSaved")?.items || [];
      search.questionnaireSaved = questionnaireSaved
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let requestForDismissal = data.find((ele) => ele.name === "requestForDismissal")?.items || [];
      search.requestForDismissal = requestForDismissal
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      await getTimesheetFilters(
        search,
        changedProperty,
        true
      );
    }
  };


  const classes = styles();
  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage} />}
      <section className="main-container-section pr-0 pb-0">
        <Grid className={`${classes.main}`}>
          <Grid
            className={`${isFilterOpen
              ? classes.filterOpenContainer
              : classes.filterCloseContainer
              }`}
          >
            <Hidden mdDown>
              <Grid container spacing={2}>
                <Grid item xs={12} className={`non-dashboard-filters-height`}>
                  {filterData.length > 0 && (
                    <Filter
                      data={[...filterData]}
                      onChange={(data: IFilter[], changedProperty?: string) => {
                        onFilterChange(data, changedProperty);
                      }}
                      isFromManagementDashboard={
                        fromManagementDashboard.current
                      }
                      managementDashboardId={
                        loadedFilter?.timeManagementDashboardId &&
                          managementDashboardId.current &&
                          loadedFilter.timeManagementDashboardId ===
                          managementDashboardId.current
                          ? managementDashboardId.current
                          : undefined
                      }
                      typeOfFilters="timesheets"
                      selectedFilters={state.search}
                      loadedFilter={loadedFilter}
                    />
                  )}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <ToggleFilterSection
            showFilterClass="toggle-filters-non-dashboard-container"
            classes={classes}
            toggleFilter={() => setFilterOpen(!isFilterOpen)}
            isFilterOpen={isFilterOpen}
          />

          <Grid
            className={` ${isFilterOpen
              ? classes.mainOpenContainer
              : classes.mainCloseContainer
              }`}
          >
            <Grid>

              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2} justifyContent="space-around">
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Card className="p-16 count-card w-100">
                        <Typography variant="h4" className="fw-600 fs-50">
                          {`${numberFormat(state.casesDashboardData.totalCases || 0)}`}
                        </Typography>
                        <Typography variant="h6"># Cases</Typography>
                      </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Card className="p-16 count-card w-100">
                        <Typography variant="h4" className="fw-600 fs-50">
                          {`${numberFormat(state.casesDashboardData.totalFirm || 0)}`}
                        </Typography>
                        <Typography variant="h6"># Firms</Typography>
                      </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Card className="p-16 count-card w-100">
                        <Typography variant="h4" className="fw-600 fs-50">
                          {`${numberFormat(state.casesDashboardData.plaintiff || 0)}`}
                        </Typography>
                        <Typography variant="h6"># Plaintiff</Typography>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Card className="p-8">
                        <Typography variant="h6" align="center">
                          Cases
                        </Typography>
                        {!state.isDataLoaded || state.casesDashboardData?.totalCases === 0 ? (
                          <Typography variant="h6" align="center">
                            No Data found
                          </Typography>
                        ) : (
                          <React.Fragment>
                            <Grid
                              container
                              spacing={1}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        isCasePercentage
                                      }
                                      onChange={(e, checked) => {
                                        setCasePercentage(
                                          checked
                                        );
                                      }}
                                      name="percentage"
                                    />
                                  }
                                  label="Percentage"
                                />
                              </Grid>
                            </Grid>
                            <div style={{ height: 400 }}>
                              <PieChart
                                data={casesStatusDetails
                                  .map((x, index) => {
                                    return {
                                      id: x.label,
                                      label: x.label,
                                      value: isCasePercentage
                                        ? state.casesDashboardData.statusByCases[x.percentageVariable]
                                        : state.casesDashboardData.statusByCases[x.countVariable],
                                      color: colorCode[index],
                                    };
                                  })}
                                valueFormat={(data: number) => {
                                  return isCasePercentage
                                    ? `${numberFormat(convertToFixed(data))}%`
                                    : numberFormat(convertToFixed(data));
                                }}
                                isDonutChart={false}
                                showLegends={true}
                                enableArcLabels={true}
                                enableArcLinkLabels={false}
                              />
                            </div>

                          </React.Fragment>
                        )}
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Card className="p-8">
                        <Typography variant="h6" align="center">
                          Firms
                        </Typography>
                        {!state.isDataLoaded || state.casesDashboardData?.totalFirm === 0 ? (
                          <Typography variant="h6" align="center">
                            No Data found
                          </Typography>
                        ) : (
                          <React.Fragment>
                            <Grid
                              container
                              spacing={1}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        isFirmPercentage
                                      }
                                      onChange={(e, checked) => {
                                        setFirmPercentage(
                                          checked
                                        );
                                      }}
                                      name="percentage"
                                    />
                                  }
                                  label="Percentage"
                                />
                              </Grid>
                            </Grid>
                            <div style={{ height: 400 }}>
                              <PieChart
                                data={casesStatusDetails
                                  .map((x, index) => {
                                    return {
                                      id: x.label,
                                      label: x.label,
                                      value: isFirmPercentage
                                        ? state.casesDashboardData.statusByFirm[x.percentageVariable]
                                        : state.casesDashboardData.statusByFirm[x.countVariable],
                                      color: colorCode[index],
                                    };
                                  })}
                                valueFormat={(data: number) => {
                                  return isFirmPercentage
                                    ? `${numberFormat(convertToFixed(data))}%`
                                    : numberFormat(convertToFixed(data));
                                }}
                                isDonutChart={false}
                                showLegends={true}
                                enableArcLabels={true}
                                enableArcLinkLabels={false}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Card className="p-8">
                        <Typography variant="h6" align="center">
                          Plaintiff
                        </Typography>
                        {!state.isDataLoaded || state.casesDashboardData?.plaintiff === 0 ? (
                          <Typography variant="h6" align="center">
                            No Data found
                          </Typography>
                        ) : (
                          <React.Fragment>
                            <Grid
                              container
                              spacing={1}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        isPlaintiffPercentage
                                      }
                                      onChange={(e, checked) => {
                                        setPlaintiffPercentage(
                                          checked
                                        );
                                      }}
                                      name="percentage"
                                    />
                                  }
                                  label="Percentage"
                                />
                              </Grid>
                            </Grid>
                            <div style={{ height: 400 }}>
                              <PieChart
                                data={casesStatusDetails
                                  .map((x, index) => {
                                    return {
                                      id: x.label,
                                      label: x.label,
                                      value: isPlaintiffPercentage
                                        ? state.casesDashboardData.statusByPlaintiff[x.percentageVariable]
                                        : state.casesDashboardData.statusByPlaintiff[x.countVariable],
                                      color: colorCode[index],
                                    };
                                  })}
                                valueFormat={(data: number) => {
                                  return isPlaintiffPercentage
                                    ? `${numberFormat(convertToFixed(data))}%`
                                    : numberFormat(convertToFixed(data));
                                }}
                                isDonutChart={false}
                                showLegends={true}
                                enableArcLabels={true}
                                enableArcLinkLabels={false}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Card className="p-8">
                        <Typography variant="h6" align="center">
                          Minors
                        </Typography>
                        {!state.isDataLoaded || state.casesDashboardData.totalMinors === 0 ? (
                          <Typography variant="h6" align="center">
                            No Data found
                          </Typography>
                        ) : (
                          <React.Fragment>
                            <Grid
                              container
                              spacing={1}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        isMinorPercentage
                                      }
                                      onChange={(e, checked) => {
                                        setMinorPercentage(
                                          checked
                                        );
                                      }}
                                      name="percentage"
                                    />
                                  }
                                  label="Percentage"
                                />
                              </Grid>
                            </Grid>
                            <div style={{ height: 400 }}>
                              <PieChart
                                data={casesStatusDetails
                                  .map((x, index) => {
                                    return {
                                      id: x.label,
                                      label: x.label,
                                      value: isMinorPercentage
                                        ? state.casesDashboardData.statusByMinor[x.percentageVariable]
                                        : state.casesDashboardData.statusByMinor[x.countVariable],
                                      color: colorCode[index],
                                    };
                                  })}
                                valueFormat={(data: number) => {
                                  return isMinorPercentage
                                    ? `${numberFormat(convertToFixed(data))}%`
                                    : numberFormat(convertToFixed(data));
                                }}
                                isDonutChart={false}
                                showLegends={true}
                                enableArcLabels={true}
                                enableArcLinkLabels={false}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </Card>
                    </Grid>



                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              typeOfFilters="timesheets"
              isFromManagementDashboard={fromManagementDashboard.current}
              managementDashboardId={
                loadedFilter?.timeManagementDashboardId &&
                  managementDashboardId.current &&
                  loadedFilter.timeManagementDashboardId ===
                  managementDashboardId.current
                  ? managementDashboardId.current
                  : undefined
              }
              selectedFilters={state.search}
              loadedFilter={loadedFilter}
            />
          </section>
        </CustomDrawer>
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(DashboardMain);
