import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import * as React from 'react';
import { StyledTableCell, StyledTableRow } from '../../services/UtilService';
import { ICase } from '../../vm';
import moment from 'moment';
import { USER_DATE_FORMAT } from '../../Constant';
interface CaseDetailTableProps {
    casesList: ICase[];
    isLoading: boolean;
    from?:"summary-view"
}

const CaseDetailTable: React.FC<CaseDetailTableProps> = ({ casesList, isLoading,from }) => {
    return (
        <React.Fragment>
            <TableContainer className={`${from==="summary-view"?'attention-details-view':'timesheets-table-container-with-employee-details'} moz-table-pb`}>
                <Table size="small" className="custom-table">
                    <TableHead>
                        <TableRow>
                            {/* <StyledTableCell>
                                                        <Checkbox
                                                            size="small"
                                                            color="secondary"
                                                            checked={state.isAllSelected}
                                                            indeterminate={
                                                                !state.isAllSelected &&
                                                                    (state.selectedTimeEntries?.length ||
                                                                        state.unselectedTimeEntries?.length)
                                                                    ? true
                                                                    : false
                                                            }
                                                            value={state.isAllSelected}
                                                            onClick={() => {
                                                                selectAll();
                                                            }}
                                                        />
                                                    </StyledTableCell> */}
                            <StyledTableCell className="plaintiff-name-cell">DEF-Plaintiff Name</StyledTableCell>
                            <StyledTableCell className="plaintiff-name-cell">Plaintiff Name Corrected</StyledTableCell>
                            <StyledTableCell>FIRM-Plaintiff Last Name</StyledTableCell>
                            <StyledTableCell>FIRM-Plaintiff Middle Name</StyledTableCell>
                            <StyledTableCell>FIRM-Plaintiff First Name </StyledTableCell>
                            <StyledTableCell className="plaintiff-name-suffix-cell">FIRM-Plaintiff Name Suffix (Jr, II, etc)</StyledTableCell>
                            <StyledTableCell>Plaintiff ID Number </StyledTableCell>
                            <StyledTableCell>DEF-INDEX NUMBER</StyledTableCell>
                            <StyledTableCell className="cell-150px">Originating Plaintiff's Counsel </StyledTableCell>
                            <StyledTableCell className="cell-150px">Updated Plaintiff's Counsel </StyledTableCell>
                            <StyledTableCell>Name of Complaint </StyledTableCell>
                            <StyledTableCell>DEF-Case Number </StyledTableCell>
                            <StyledTableCell>FIRM-Case Number</StyledTableCell>
                            <StyledTableCell>DEF-Minor </StyledTableCell>
                            <StyledTableCell>FIRM-Minor </StyledTableCell>
                            <StyledTableCell>DEF-Complaint Type</StyledTableCell>
                            <StyledTableCell>Classification Type</StyledTableCell>
                            <StyledTableCell>Filing Date of Complaint</StyledTableCell>
                            <StyledTableCell>Request for Dismissal Filed/Served</StyledTableCell>
                            <StyledTableCell>Dismissal Filing Date </StyledTableCell>
                            <StyledTableCell>DEF-Dismissal Entered Date</StyledTableCell>
                            <StyledTableCell>Questionnaire Served</StyledTableCell>
                            <StyledTableCell>Questionnaire Served Date </StyledTableCell>
                            <StyledTableCell>To Be Dismissed WITHOUT Prejudice</StyledTableCell>
                            <StyledTableCell>To Be Dismissed WITH Prejudice</StyledTableCell>
                            <StyledTableCell>SUBBED OUT/Client Withdrew</StyledTableCell>
                            <StyledTableCell>Plaintiff RFD-Index Number</StyledTableCell>
                            <StyledTableCell>Defendant RFD Tracking Number</StyledTableCell>
                            {/* <StyledTableCell>Actions</StyledTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {casesList.length > 0 ? (
                            casesList.map((ele, index: number) => (
                                <StyledTableRow key={`case_detail_${index}`}>
                                    {/* <StyledTableCell>
                                                                <Checkbox
                                                                    size="small"
                                                                    checked={ele.isSelected ? true : false}
                                                                    // value={ele.isSelected}
                                                                    onClick={() => {
                                                                        handleSelectChange(index);
                                                                    }}
                                                                />
                                                            </StyledTableCell> */}
                                    <StyledTableCell className="plaintiff-name-cell">{ele.defPlaintiffName || ""}</StyledTableCell>
                                    <StyledTableCell className="plaintiff-name-cell">{ele.plaintiffNameCorrected || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.lastName || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.middleName || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.firstName || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.nameSuffix || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.plaintiffNumberId || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.plaintiffRfdIndexNumber || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.originatingPlaintiffCounsel || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.updatedPlaintiffCounsel || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.complaintName || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.defCaseNumber || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.firmCaseNumber || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.defMinor || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.firmMinor || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.complaintType || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.classificationType || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.complaintDate ? moment(ele.complaintDate).format(USER_DATE_FORMAT) : ""}</StyledTableCell>
                                    <StyledTableCell>{ele.requestForDismissalServed || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.dismissalFilingDate ? moment(ele.dismissalFilingDate).format(USER_DATE_FORMAT) : ""}</StyledTableCell>
                                    <StyledTableCell>{ele.defDismissalEnteredDate ? moment(ele.defDismissalEnteredDate).format(USER_DATE_FORMAT) : ""}</StyledTableCell>
                                    <StyledTableCell>{ele.questionnaireServed || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.questionnaireServedDate ? moment(ele.questionnaireServedDate).format(USER_DATE_FORMAT) : ""}</StyledTableCell>
                                    <StyledTableCell>{ele.dismissedWithoutPrejudice || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.dismissedWithPrejudice || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.subbedOutOrClientWithdrew || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.plaintiffRfdIndexNumber || ""}</StyledTableCell>
                                    <StyledTableCell>{ele.defendantRfdTrackingNumber || ""}</StyledTableCell>

                                    {/* <StyledTableCell>
                                                                <Grid container spacing={1} justify="center">
                                                                    <Grid item>
                                                                        <Tooltip
                                                                            title="View Detailed Report"
                                                                            placement="bottom"
                                                                        >
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={
                                                                                    () => viewTimesheet(ele)
                                                                                }
                                                                            >
                                                                                <OpenInNew />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell> */}
                                </StyledTableRow>
                            ))
                        ) : (
                            <React.Fragment>
                                {!isLoading && (
                                    <TableRow>
                                        <TableCell align="center" colSpan={28}>
                                            No cases found{" "}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}

export default CaseDetailTable;