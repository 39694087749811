import {
    Card,
    Grid,
    Hidden,
    IconButton,
    TablePagination,
    Typography,
} from "@material-ui/core";
import * as React from "react";
import {
    IFilter,
    ISelectedFirms,
    ITimeEntry,
    IConfirmDialog,
    ITimeFilter,
    IProfessionalHourStats,
    ICaseSearch,
    IAttentionCaseDetails,
    ICaseDashboard,
    IReactSelect,
} from "../../vm";
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight,
} from "mdi-material-ui";
import { useHistory, useLocation } from "react-router-dom";
import Filter from "../common/Filter";
import { ToastContext } from "../common/ToastProvider";
import {
    getAllCasesAttentionList,
    getCasesFiltersCount,
    getCompliantNames,
    getFirmNames,
    getPlaintiffNames,
} from "../../services/CaseService";
import Loading from "../common/Loading";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    getCeilValueForTotalCount,
    numberFormat,
    onlyUnique,
} from "../../services/UtilService";
import {
    styles,
    TIMESHEET_STATUS,
    MAX_PAGINATION_OPTIONS,
} from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import ToggleFilterSection from "../dashboard/ToggleFilter";
import "./cases.css"
import CaseAttentionTable from "./CaseAttenttionTable";
import { getAllCasesDashboard } from "../../services/DashboardService";
export interface CaseAttentionDetailMainProps extends IConfirmDialog { }

const CaseAttentionDetailMain: React.FC<CaseAttentionDetailMainProps> = ({ confirmDialog }) => {
    const history = useHistory();
    const location = useLocation();
    const fromManagementDashboard = React.useRef(false);
    const managementDashboardId = React.useRef(undefined);
    const [isFilterOpen, setFilterOpen] = React.useState(true);
    const [loadedFilter, setLoadedFilter] = React.useState(
        undefined as ITimeFilter
    );
    let searchObj: any = {};

    const [state, setState] = React.useState({
        casesList: [],
        selectedTimeEntries: [],
        unselectedTimeEntries: [],
        selectedFirmDetails: [],
        hasAllSelectionChanged: false,
        isAllSelected: false,
        manageTimeEntryDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
            isBulkEdit: false,
        },
        search: {
            from: 0,
            size: searchObj?.size ? searchObj.size : 10,
            showOnlyDisapproved: false,
            classificationType: [],
            complaintType: [],
            defMinor: [],
            questionnaireSaved: [],
            requestForDismissal: [],
            plaintiffNames: [],
            plaintiffCounsel: [],
            showPlaintiffNamesCorrected: false,
            showDefMinor: "",
            complaintName: "",
            caseNumber: "",
            startDismissalFilingDate: "",
            endDismissalFilingDate: "",
            statuses: [],
            startDefDismissalEnteredDate: "",
            endDefDismissalEnteredDate: "",
            startQuestionnaireServedDate: "",
            endQuestionnaireServedDate: "",

        },
        casesCount: 0,
        filterData: [],
        rowsList: [10],
        casesDashboardData: {}
    } as {
        search: ICaseSearch;
        casesDashboardData: ICaseDashboard;
        casesList: IAttentionCaseDetails[];
        selectedTimeEntries: string[];
        unselectedTimeEntries: string[];
        hasAllSelectionChanged: boolean;
        isAllSelected: boolean;
        selectedFirmDetails: ISelectedFirms[];
        manageTimeEntryDialog: {
            isOpen: boolean;
            data?: ITimeEntry;
            index?: number;
            isBulkEdit: boolean;
        };
        casesCount: number;
        filterData: IFilter[];
        rowsList: number[];
        options: { value: string; label: string }[];
    });

    const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
    const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState("");

    const { showToast } = React.useContext(ToastContext);
    const [filterData, setFilterData] = React.useState([] as IFilter[]);

    const [isCountsLoaded, setCountsLoaded] = React.useState(false);
    const [filterCountsDic, setFilterCountsDic] = React.useState({});
    const [allEmployeeDetails, setAllEmployeeDetails] = React.useState(
        {} as IProfessionalHourStats
    );
    const [lastPageSize, setLastPageSize] = React.useState(
        state.search.size || 10
    );

    React.useEffect(() => {
        updateState(location.state);
    }, [location.state,location.pathname]);

    const updateState = (stateFromLocation?: any) => {
        let searchObj = { ...state.search };
        if (stateFromLocation) {
            let searchState = { ...(stateFromLocation as ICaseSearch) };
            searchObj.showOnlyDisapproved = searchState.showOnlyDisapproved || false;
            searchObj.classificationType = searchState.classificationType || [];
            searchObj.complaintType = searchState.complaintType || [];
            searchObj.defMinor = searchState.defMinor || [];
            searchObj.questionnaireSaved = searchState.questionnaireSaved || [];
            searchObj.requestForDismissal = searchState.requestForDismissal || [];
            searchObj.plaintiffNames = searchState.plaintiffNames || [];
            searchObj.plaintiffCounsel = searchState.plaintiffCounsel || [];
            searchObj.showPlaintiffNamesCorrected = searchState.showPlaintiffNamesCorrected || false;
            searchObj.showDefMinor = searchState.showDefMinor || "";
            searchObj.complaintName = searchState.complaintName || "";
            searchObj.caseNumber = searchState.caseNumber || "";
            searchObj.startDismissalFilingDate = searchState.startDismissalFilingDate || "";
            searchObj.endDismissalFilingDate = searchState.endDismissalFilingDate || "";
            searchObj.statuses = searchState.statuses || [];
            searchObj.startDefDismissalEnteredDate = searchState.startDefDismissalEnteredDate || "";
            searchObj.endDefDismissalEnteredDate = searchState.endDefDismissalEnteredDate || "";
            searchObj.startQuestionnaireServedDate = searchState.startQuestionnaireServedDate || "";
            searchObj.endQuestionnaireServedDate = searchState.endQuestionnaireServedDate || "";
        }
        let obj: any = {
            from: 0,
            size: searchObj?.size ? searchObj.size : 10,
            ...searchObj
        };
        setState({
            ...state,
            search: obj,
        });
        getAllRecordsFromStarting(obj);
    };

    const getAllRecordsFromStarting = async (search?) => {
        setLoading(true);
        // await getTimesheetFilters();
        await getCasesList();
        setLoading(false);
    };

    const getPlainTiffSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
        let result = await getPlaintiffNames(inputValue);
        return result.data.map(x => {
            return {
                label: x.defPlaintiffName,
                value: x.defPlaintiffName,
            }
        });
    }
    const getFirmsSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
        let result = await getFirmNames(inputValue);
        return result.data.map(x => {
            return {
                label: x,
                value: x,
            }
        });
    }
    const getCompliantSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
        let result = await getCompliantNames(inputValue);
        return result.data.map(x => {
            return {
                label: x,
                value: x,
            }
        });
    }

    const getTimesheetFilters = async (
        search?: any,
        changedProperty?: any,
        resetSelectAll?: boolean
    ) => {
        let tempSearch = { ...state.search };
        if (search) {
            tempSearch = { ...state.search, ...search };
            if (changedProperty === "load-filters") {
                tempSearch = { ...search, from: 0, size: tempSearch.size };
            }
        }

        delete tempSearch.size;
        delete tempSearch.from;
        setLoading(true);

        let result = {
            isSuccess: true,
            success: true,
            message: "",
            data: {
                classificationType: [],
                statuses: [],
                complaintType: [],
                defMinor: [],
                questionnaireSaved: [],
                requestForDismissal: [],
            } as any,
        };

        if (isCountsLoaded !== false && changedProperty !== "clear-filters") {
            let filterKeys = [
                "classificationType",
                "complaintType",
                "statuses",
                "defMinor",
                "questionnaireSaved",
                "requestForDismissal"
            ];
            filterKeys.forEach(key => {
                result.data[key] = filterData.find(x => x.name === key)?.items.map(x => {
                    return { label: x.label, value: x.name }
                });
            })
        }

        if (isCountsLoaded === false || changedProperty === "clear-filters") {
            result = await getCasesFiltersCount();
        }
        setLoading(false);
        if (result?.isSuccess) {
            let filterCountDic = filterCountsDic;
            if (isCountsLoaded === false || changedProperty === "clear-filters") {
                let keyParams = {
                    classificationType: "classificationType",
                    complaintType: "complaintType",
                    statuses: "statuses",
                    defMinor: "defMinor",
                    questionnaireSaved: "questionnaireSaved",
                    requestForDismissal: "requestForDismissal",
                };
                filterCountDic = Object.keys(result.data).reduce(
                    (acc, key) => {
                        if (acc[key]) {
                            result.data[key].forEach((data) => {
                                acc[key][data[keyParams[key]]] = data;
                            });
                        }
                        return acc;
                    },
                    {
                        classificationType: [],
                        complaintType: [],
                        statuses: [],
                        defMinor: [],
                        questionnaireSaved: [],
                        requestForDismissal: []
                    }
                );
                setFilterCountsDic(filterCountDic);
            }
            let data = { ...result.data };

            let filterOrder = [
                "classificationType",
                "statuses",
                "showPlaintiffNamesCorrected",
                "plaintiffName",
                "firmName",
                "compliantName",
                "caseNumber",
                "complaintType",
                "defMinor",
                "questionnaireSaved",
                "requestForDismissal",
                "filingDate",
                "dismissalFilingDate",
                "defDismissalEnteredDate",
                "questionnaireServedDate",
            ];
            let customFilters = [
                "filingDate",
                "dismissalFilingDate",
                "defDismissalEnteredDate",
                "questionnaireServedDate",
                "plaintiffName",
                "firmName",
                "compliantName",
                "caseNumber",
            ];
            let filter: IFilter[] = [];

            filterOrder.forEach((filterType) => {
                if (customFilters.indexOf(filterType) > -1) {
                    switch (filterType) {
                        case "filingDate":
                            filter.push({
                                header: "Filing Date",
                                name: "filingDate",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "filingDate")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: "date-range",
                                        value: [
                                            {
                                                startDate: tempSearch.startFilingDate,
                                                endDate: tempSearch.endFilingDate,
                                                key: "selection",
                                            },
                                        ],
                                        label: "",
                                    },
                                ],
                            });
                            break;
                        case "dismissalFilingDate":
                            filter.push({
                                header: "Dismissal Filing Date",
                                name: "dismissalFilingDate",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "dismissalFilingDate")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: "date-range",
                                        value: [
                                            {
                                                startDate: tempSearch.startDismissalFilingDate,
                                                endDate: tempSearch.endDismissalFilingDate,
                                                key: "selection",
                                            },
                                        ],
                                        label: "",
                                    },
                                ],
                            });
                            break;
                        case "defDismissalEnteredDate":
                            filter.push({
                                header: "Def Dismissal Entered Date",
                                name: "defDismissalEnteredDate",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "defDismissalEnteredDate")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: "date-range",
                                        value: [
                                            {
                                                startDate: tempSearch.startDefDismissalEnteredDate,
                                                endDate: tempSearch.endDefDismissalEnteredDate,
                                                key: "selection",
                                            },
                                        ],
                                        label: "",
                                    },
                                ],
                            });
                            break;
                        case "questionnaireServedDate":
                            filter.push({
                                header: "Questionnaire Served Date",
                                name: "questionnaireServedDate",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "questionnaireServedDate")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: "date-range",
                                        value: [
                                            {
                                                startDate: tempSearch.startQuestionnaireServedDate,
                                                endDate: tempSearch.endQuestionnaireServedDate,
                                                key: "selection",
                                            },
                                        ],
                                        label: "",
                                    },
                                ],
                            });
                            break;
                        case "plaintiffName":
                            filter.push({
                                header: "",
                                name: "plaintiffName",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        label: "Plaintiff Name",
                                        type: "dropdown-dynamic",
                                        name: filterType,
                                        selectedItems: tempSearch.plaintiffNames.map(x => {
                                            return { label: x, value: x }
                                        }),
                                        options: [],
                                        asyncCallBackFunction: getPlainTiffSearchName,
                                        isMulti: true
                                    },
                                ],
                            });
                            break;
                        case "firmName":
                            filter.push({
                                header: "",
                                name: "firmName",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        label: "Firm Name",
                                        type: "dropdown-dynamic",
                                        name: filterType,
                                        selectedItems: tempSearch.plaintiffCounsel.map(x => {
                                            return { label: x, value: x }
                                        }),
                                        options: [],
                                        asyncCallBackFunction: getFirmsSearchName,
                                        isMulti: true
                                    },
                                ],
                            });
                            break;
                        case "compliantName":
                            filter.push({
                                header: "",
                                name: "compliantName",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        label: "Compliant Name",
                                        type: "dropdown-dynamic",
                                        name: filterType,
                                        selectedItems: tempSearch.complaintName?.length > 0 ? [{ label: tempSearch.complaintName, value: tempSearch.complaintName }] : [],
                                        options: [],
                                        asyncCallBackFunction: getCompliantSearchName,
                                        isMulti: true
                                    },
                                ],
                            });
                            break;
                        case "caseNumber":
                            filter.push({
                                header: "Case Number",
                                name: "caseNumber",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "caseNumber").isHidden) ||
                                    false,
                                items: [{
                                    type: "text",
                                    value: tempSearch.caseNumber,
                                    name: "caseNumber",
                                    label: ""
                                }],
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    let ele = filterType;
                    let a: IFilter = { header: "", items: [], name: ele };
                    switch (ele) {
                        case "classificationType":
                            a.header = "Classification Type";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let classificationType = data["classificationType"] || [];
                            classificationType.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.classificationType?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "statuses":
                            a.header = "Status";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let statuses = data["statuses"] || [];
                            statuses.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.statuses?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "showPlaintiffNamesCorrected":
                            filter.push({
                                className: "disapproved-section",
                                header: "",
                                name: "showPlaintiffNamesCorrected",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "showPlaintiffNamesCorrected")
                                            ?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        isSelected: tempSearch.showPlaintiffNamesCorrected,
                                        label: "Show Plaintiff Names Corrected",
                                        type: "switch",
                                        name: "showPlaintiffNamesCorrected"
                                    },
                                ],
                            });
                            break;
                        case "complaintType":
                            a.header = "Complaint Type";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let complaintType = data["complaintType"] || [];
                            complaintType.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.complaintType?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "defMinor":
                            a.header = "DEF Minor";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let defMinor = data["defMinor"] || [];
                            defMinor.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.defMinor?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "questionnaireSaved":
                            a.header = "Questionnaire Saved";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let questionnaireSaved = data["questionnaireSaved"] || [];
                            questionnaireSaved.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.questionnaireSaved?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "requestForDismissal":
                            a.header = "Request For Dismissal";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let requestForDismissal = data["requestForDismissal"] || [];
                            requestForDismissal.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.requestForDismissal?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "statusCounts":
                            a.header = "Status";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let statusCounts = data["statusCounts"] || [];
                            Object.keys(TIMESHEET_STATUS).map((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.statuses?.includes(eds) || false,
                                    label: TIMESHEET_STATUS[eds],
                                    name: eds,
                                    type: "checkbox",
                                    count:
                                        isCountsLoaded === false
                                            ? changedProperty !== undefined &&
                                                changedProperty === ele &&
                                                tempSearch.statuses.length > 0
                                                ? filterData
                                                    .find((ed) => ed.name === ele)
                                                    ?.items.find((ed) => ed.name === eds)?.count || 0
                                                : statusCounts.find((e) => e.status === eds)?.count || 0
                                            : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;

                        default:
                            a = undefined;
                            break;
                    }
                    if (a) {
                        filter.push(a);
                    }
                }
            });


            setCountsLoaded(true);
            setFilterData(filter);

            if (changedProperty !== "add-descriptions") {
                // tempSearch.from = 0;
                // tempSearch.size = 10;

                await getCasesList(
                    tempSearch,
                    resetSelectAll,
                    changedProperty,
                    filter
                );
            }
        } else {
            showToast(
                result.message || "Error while fetching cases counts",
                "error"
            );
        }
    };

    const getCasesList = async (
        search?: any,
        resetSelectAll?: boolean,
        changedProperty?: string,
        filter?: IFilter[],
        isAllSelectedFromRowsPerPage?: boolean
    ) => {
        let tempSearch = { ...state.search };
        if (search) {
            tempSearch = { ...state.search, ...search };
        }

        let searchObj = { ...tempSearch };
        setLoading(true);

        if (
            !isAllSelectedFromRowsPerPage &&
            searchObj.size === getCeilValueForTotalCount(state.casesCount)
        ) {
            searchObj.size = lastPageSize;
            tempSearch.size = lastPageSize;
        }
        const result = await getAllCasesAttentionList(searchObj);
        if (result?.isSuccess) {
            // let isAllSelected = state.isAllSelected;
            // let selectedTimeEntries = [...state.selectedTimeEntries];
            // let unselectedTimeEntries = [...state.unselectedTimeEntries];
            // let hasAllSelectionChanged = state.hasAllSelectionChanged;
            // if (hasAllSelectionChanged) {
            //     selectedTimeEntries = [
            //         ...selectedTimeEntries,
            //         // ...result.data.timeEntryCases
            //         //     .filter(
            //         //         (x) =>
            //         //             state.unselectedTimeEntries.indexOf(
            //         //                 `${x.timeEntryCasesId}`
            //         //             ) === -1
            //         //     )
            //         //     .map((x) => `${x.timeEntryCasesId}`),
            //     ];
            // }
            // if (resetSelectAll) {
            //     isAllSelected = false;
            //     hasAllSelectionChanged = false;
            //     selectedTimeEntries = [];
            //     unselectedTimeEntries = [];
            // }
            // let menuItems = [];
            // for (let index = 10; index <= result.data.count; index = index + 10) {
            //     menuItems.push(index);
            // }
            // if (menuItems[menuItems.length - 1] < result.data.count) {
            //     menuItems.push(getCeilValueForTotalCount(result.data.count));
            // }
            // if (menuItems.length > MAX_PAGINATION_OPTIONS) {
            //     menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
            // }

            // // checking is the size is more than the data count
            // if (tempSearch.size > result.data.count) {
            //     setLastPageSize(tempSearch.size);
            //     tempSearch.size = getCeilValueForTotalCount(result.data.count) || 10;
            // }
            const dashboardData = await getAllCasesDashboard(searchObj);
            setState({
                ...state,
                // casesCount: result.data.count,
                manageTimeEntryDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    isBulkEdit: false,
                },
                casesDashboardData: dashboardData.data,
                casesList: [...result.data],
                // rowsList: [...menuItems],
                // search: { ...state.search, ...tempSearch },
                // isAllSelected,
                // hasAllSelectionChanged,
                // selectedTimeEntries: selectedTimeEntries.filter(onlyUnique),
                // unselectedTimeEntries,
            });
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching cases",
                "error"
            );
        }
        setLoading(false);
    };

    const onSearch = async (
        searchObj?: any,
        isAllSelectedFromRowsPerPage?: boolean
    ) => {
        let tempSearch = { ...state.search };
        if (searchObj) {
            tempSearch = { ...state.search, ...searchObj };
        }
        await getCasesList(
            tempSearch,
            undefined,
            undefined,
            undefined,
            isAllSelectedFromRowsPerPage
        );
    };

    const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
        if (changedProperty === "clear-filters") {
            setLoadedFilter(undefined);
            managementDashboardId.current = undefined;
        }
        if (changedProperty === "load-filters") {
            let details = JSON.parse(JSON.stringify(data));
            setLoadedFilter(details);
            await getTimesheetFilters(
                details.filters,
                changedProperty,
                true
            );
        } else {
            let search = { ...state.search };
            search.from = 0;
            let classificationType = data.find((ele) => ele.name === "classificationType")?.items || [];
            search.classificationType = classificationType
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let complaintType = data.find((ele) => ele.name === "complaintType")?.items || [];
            search.complaintType = complaintType
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let statuses = data.find((ele) => ele.name === "statuses")?.items || [];
            search.statuses = statuses
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let showPlaintiffNamesCorrected =
                data.find((ele) => ele.name === "showPlaintiffNamesCorrected")?.items || [];
            search.showPlaintiffNamesCorrected = showPlaintiffNamesCorrected.find(
                (ele) => ele.name === "showPlaintiffNamesCorrected"
            ).isSelected;


            let plaintiffName =
                data.find((ele) => ele.name === "plaintiffName")?.items || [];
            search.plaintiffNames = plaintiffName[0].selectedItems.map(x => x.value);
            let firmName =
                data.find((ele) => ele.name === "firmName")?.items || [];
            search.plaintiffCounsel = firmName[0].selectedItems.map(x => x.value);
            let compliantName =
                data.find((ele) => ele.name === "compliantName")?.items || [];
            search.complaintName = compliantName[0].selectedItems.length > 0 ? compliantName[0].selectedItems[0].value : "";

            let caseNumber =
                data.find((ele) => ele.name === "caseNumber")?.items || [];
            search.caseNumber = caseNumber[0].value;

            let filingDate = data.find((ele) => ele.name === "filingDate")?.items || [];
            search.startFilingDate = filingDate[0].value[0].startDate || undefined;
            search.endFilingDate = filingDate[0].value[0].endDate || undefined;

            let dismissalFilingDate = data.find((ele) => ele.name === "dismissalFilingDate")?.items || [];
            search.startDismissalFilingDate = dismissalFilingDate[0].value[0].startDate || undefined;
            search.endDismissalFilingDate = dismissalFilingDate[0].value[0].endDate || undefined;

            let defDismissalEnteredDate = data.find((ele) => ele.name === "defDismissalEnteredDate")?.items || [];
            search.startDefDismissalEnteredDate = defDismissalEnteredDate[0].value[0].startDate || undefined;
            search.endDefDismissalEnteredDate = defDismissalEnteredDate[0].value[0].endDate || undefined;

            let questionnaireServedDate = data.find((ele) => ele.name === "questionnaireServedDate")?.items || [];
            search.startQuestionnaireServedDate = questionnaireServedDate[0].value[0].startDate || undefined;
            search.endQuestionnaireServedDate = questionnaireServedDate[0].value[0].endDate || undefined;

            let defMinor = data.find((ele) => ele.name === "defMinor")?.items || [];
            search.defMinor = defMinor
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let questionnaireSaved = data.find((ele) => ele.name === "questionnaireSaved")?.items || [];
            search.questionnaireSaved = questionnaireSaved
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let requestForDismissal = data.find((ele) => ele.name === "requestForDismissal")?.items || [];
            search.requestForDismissal = requestForDismissal
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            await getTimesheetFilters(
                search,
                changedProperty,
                true
            );
        }
    };

    const TablePaginationActions = (props: TablePaginationActionsProps) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className="d-flex">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <ChevronDoubleLeft />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <ChevronDoubleRight />
                </IconButton>
            </div>
        );
    };

    const classes = styles();
    return (
        <React.Fragment>
            {isLoading && <Loading message={loadingMessage} />}
            <section className="main-container-section pr-0 pb-0">
                <Grid className={`${classes.main}`}>
                    {/* <Grid
                        className={`${isFilterOpen
                            ? classes.filterOpenContainer
                            : classes.filterCloseContainer
                            }`}
                    >
                        <Hidden mdDown>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={`non-dashboard-filters-height`}>
                                    {filterData.length > 0 && (
                                        <Filter
                                            data={[...filterData]}
                                            options={state.options || []}
                                            onChange={(data: IFilter[], changedProperty?: string) => {
                                                onFilterChange(data, changedProperty);
                                            }}
                                            isFromManagementDashboard={
                                                fromManagementDashboard.current
                                            }
                                            managementDashboardId={
                                                loadedFilter?.timeManagementDashboardId &&
                                                    managementDashboardId.current &&
                                                    loadedFilter.timeManagementDashboardId ===
                                                    managementDashboardId.current
                                                    ? managementDashboardId.current
                                                    : undefined
                                            }
                                            typeOfFilters="timesheets"
                                            selectedFilters={state.search}
                                            loadedFilter={loadedFilter}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <ToggleFilterSection
                        showFilterClass="toggle-filters-non-dashboard-container"
                        classes={classes}
                        toggleFilter={() => setFilterOpen(!isFilterOpen)}
                        isFilterOpen={isFilterOpen}
                    /> */}

                    <Grid
                        className={`non-dashboard-main-container ${isFilterOpen
                            ? classes.mainOpenContainer
                            : classes.mainCloseContainer
                            }`}
                    >

                        <Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={2} justifyContent="space-around">
                                        <Grid item lg={4} md={4} sm={4} xs={12}>
                                            <Card className="p-16 count-card w-100">
                                                <Typography variant="h4" className="fw-600 fs-50">
                                                    {`${numberFormat(state.casesDashboardData.totalCases || 0)}`}
                                                </Typography>
                                                <Typography variant="h6"># Cases</Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={12}>
                                            <Card className="p-16 count-card w-100">
                                                <Typography variant="h4" className="fw-600 fs-50">
                                                    {`${numberFormat(state.casesDashboardData.totalFirm || 0)}`}
                                                </Typography>
                                                <Typography variant="h6"># Firms</Typography>
                                            </Card>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={12}>
                                            <Card className="p-16 count-card w-100">
                                                <Typography variant="h4" className="fw-600 fs-50">
                                                    {`${numberFormat(state.casesDashboardData.plaintiff || 0)}`}
                                                </Typography>
                                                <Typography variant="h6"># Plaintiff</Typography>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <CaseAttentionTable caseEntries={state.casesList} />
                                    {/* <TablePagination
                                        rowsPerPageOptions={state.rowsList}
                                        component="div"
                                        count={state.casesCount}
                                        rowsPerPage={state.search.size}
                                        page={
                                            state.search.from === 0
                                                ? 0
                                                : state.search.from / state.search.size
                                        }
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onPageChange={(event: any, newPage: number) => {
                                            console.log("event", event);
                                            console.log("newPage", newPage);
                                            let search = { ...state.search };
                                            search.from =
                                                newPage === 0 ? 0 : Number(newPage) * state.search.size;
                                            onSearch(search);
                                        }}
                                        onRowsPerPageChange={(event: any) => {
                                            let value = event.target.value;
                                            let search = { ...state.search };
                                            search.size = value ? Number(value) : 10;
                                            search.from = 0;
                                            onSearch(
                                                search,
                                                search.size ==
                                                    getCeilValueForTotalCount(state.casesCount)
                                                    ? true
                                                    : false
                                            );
                                        }}
                                        ActionsComponent={TablePaginationActions}
                                    /> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            {isFilterDialogOpen && (
                <CustomDrawer
                    title={"Filter"}
                    onClose={() => setFilterDialogOpen(false)}
                >
                    <section className="p-24">
                        <Filter
                            isPopup={true}
                            data={[...filterData]}
                            onChange={(data: IFilter[]) => {
                                onFilterChange(data);
                            }}
                            typeOfFilters="timesheets"
                            isFromManagementDashboard={fromManagementDashboard.current}
                            managementDashboardId={
                                loadedFilter?.timeManagementDashboardId &&
                                    managementDashboardId.current &&
                                    loadedFilter.timeManagementDashboardId ===
                                    managementDashboardId.current
                                    ? managementDashboardId.current
                                    : undefined
                            }
                            selectedFilters={state.search}
                            loadedFilter={loadedFilter}
                        />
                    </section>
                </CustomDrawer>
            )}

        </React.Fragment>
    );
};

export default withConfirmDialogContext(CaseAttentionDetailMain);
