import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import NavBar from "./components/common/NavBar";
import RoutesMain from "./RoutesMain";
import Loading from "./components/common/Loading";
import { checkDbExistsAPI, getUserIP } from "./services/auth/AuthService";
import { URL_PATHS } from "./Constant";
import { IsReadOnlyContext } from "./components/common/IsReadOnlyProvider";
import { checkIsReadOnlyApi } from "./services/UserService";
import { isTokenExpired } from "./services/UtilService";

export interface RoutesProps { }

const Routes: React.FC<RoutesProps> = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [authCheckDone, setAuthCheckDone] = React.useState(true);
  const { updateStatus, setUserIP } = React.useContext(IsReadOnlyContext);
  React.useEffect(() => {
    // checkDbExists();
    getCurrentUserIP();
    // if (!isTokenExpired()) {
    //   checkIsReadOnly();
    // }
  }, []);
  const checkDbExists = async () => {
    if (window.location.href.indexOf(URL_PATHS.DB_DOES_NOT_EXISTS) === -1 && window.location.href.indexOf("acceptable-use-policy") === -1 && window.location.href.indexOf("end-user-license-agreement") === -1) {
      const result = await checkDbExistsAPI();
      setLoading(false);
      if (result?.isSuccess) {
        setAuthCheckDone(true);
      } else {
        // showToast(
        //   result.message || "DB doesn't exists. Please check with administration",
        //   "error"
        // );
        window.location.href = `/${URL_PATHS.DB_DOES_NOT_EXISTS}`;
      }
    } else {
      setLoading(false);
      setAuthCheckDone(true);
    }
  };
  const getCurrentUserIP = async () => {
      let result = await getUserIP();
      if(result.ip){
        setUserIP(result.ip);
      }
  };

  const checkIsReadOnly = async () => {
    setLoading(true);
    const result = await checkIsReadOnlyApi();
    setLoading(false);
    if (result?.isSuccess) {
      updateStatus(result.data.isReadonly);
    } else {
      updateStatus(true);
    }
  };

  return (
    <Router>
      {isLoading && <Loading />}
      {authCheckDone && (
        <div style={{ display: "flex" }}>
          <CssBaseline />
          <NavBar />
          <RoutesMain />
        </div>
      )}
    </Router>
  );
};

export default Routes;
