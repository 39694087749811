import React from "react";
import { Alert } from "@material-ui/lab";
import { IS_READ_ONLY_ALERT_TEXT } from "../../Constant";

const IsReadOnlyAlert = () => {
    return (
        <div>
            <Alert severity="warning" style={{ padding: "0px 16px" }}>{IS_READ_ONLY_ALERT_TEXT}</Alert>
        </div>
    );
};

export default IsReadOnlyAlert;