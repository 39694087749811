import * as React from "react";
import DashboardTabMain from "./DashboardTabMain";

export interface DashboardMainProps {}

const DashboardMain: React.FC<DashboardMainProps> = () => {

  return (
    <React.Fragment>
      <DashboardTabMain />
      {/* <AppBar position="static" color="default">
        <Tabs value={state.tabValue} onChange={handleTabValueChange}>
          <Tab label="Dashboard" />
          <Tab label="Timesheets" />
          <Tab label="Firms" />
        </Tabs>
      </AppBar>
      <section>
        {state.tabValue === 0 && <DashboardTabMain />}
        {state.tabValue === 1 && <TimesheetDashboardMain isFirm={false} />}
        {state.tabValue === 2 && <FirmsDashboardMain />}
      </section> */}
    </React.Fragment>
  );
};

export default DashboardMain;
