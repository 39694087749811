import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Popover,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { IFilter, ITimeFilter } from "../../vm";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { debounce } from "../../services/UtilService";
import { Calendar, ChevronDown, ChevronRight, ChevronUp, Close, Plus } from "mdi-material-ui";
import { ToastContext } from "./ToastProvider";
import DatePicker from "react-date-picker";
import { DESCRIPTION_FILTER_ENTRY, TREE_MONTH_DATE_FORMAT, TREE_YEAR_DATE_FORMAT } from "../../Constant";
import "./filter.css";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ReactQueryBuilder from "./query-builder/ReactQueryBuilder";
import CustomDrawer from "./CustomDrawer";
import { ConfirmDialogContext } from "./ConfirmDialogProvider";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addYears, endOfYear, isSameDay, startOfYear } from "date-fns";
import { DateRange, DateRangePicker, defaultStaticRanges } from "react-date-range";
import { IsReadOnlyContext } from "./IsReadOnlyProvider";

export interface FilterProps {
  isPopup?: boolean;
  isFromManagementDashboard?: boolean;
  managementDashboardId?: number;
  data: IFilter[];
  onChange: Function;
  options?: { value: string; label: string }[];
  typeOfFilters?: "timesheets" | "expense";
  selectedFilters?: any;
  loadedFilter?: ITimeFilter;
  isFilterOpen?: boolean;
  handleFilterHide?: Function;
  dateRangePickerMinMaxObj?: { minDate?: Date; maxDate?: Date };
}

var previousFilterData;
const Filter: React.FC<FilterProps> = ({
  data,
  onChange,
  options,
  isPopup,
  typeOfFilters,
  isFromManagementDashboard,
  managementDashboardId,
  selectedFilters,
  loadedFilter,
  isFilterOpen,
  handleFilterHide,
  dateRangePickerMinMaxObj
}) => {
  const [state, setState] = React.useState({
    filterData: [...data],
    initialData: [...data],
  } as { filterData: IFilter[]; initialData: IFilter[] });

  const loadedFilterRef = React.useRef(loadedFilter);

  const [saveFilterDialog, setSaveFilterDialog] = React.useState({
    isOpen: false,
    isSaveFilter: true
  });

  const showLoadPopover = React.useRef(true);

  const [loadFilterDialog, setLoadFilterDialog] = React.useState({
    isOpen: false,
  });
  const [queryBuilderDialog, setQueryBuilderDialog] = React.useState({
    isOpen: false,
    jsonData: undefined,
    sqlQuery: "",
  } as {
    isOpen: boolean;
    jsonData: any;
    sqlQuery: string;
  });
  const [isAdvancedFilterApplied, setAdvancedFilterApplied] = React.useState(false as boolean);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);

  const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);
  const { showToast } = React.useContext(ToastContext);
  const [actionAnchorEl, setActionAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = React.useState([] as string[]);
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [dateRangeAnchorElIndex, setDateRangeAnchorElIndex] = React.useState<number | null>(null);
  React.useEffect(() => {
    const fetchTimesheetsList = async () => {
      setState({ filterData: [...data], initialData: [...data] });
    };
    previousFilterData = JSON.parse(JSON.stringify(data));
    if (typeOfFilters === "timesheets" && showLoadPopover.current && isFromManagementDashboard) {
      showLoadPopover.current = false;
      document.getElementById("action_menu_button").click();
      setTimeout(() => {
        setPopoverAnchorEl(document.getElementById("action_load_filter"));
      }, 500);
    }
    fetchTimesheetsList();
  }, [data]);

  const clearFilters = async () => {
    let filterData = [...state.filterData];
    // set advanced filter as false and reset the query builder state
    setAdvancedFilterApplied(false);
    setQueryBuilderDialog({
      isOpen: false,
      jsonData: undefined,
      sqlQuery: "",
    });
    filterData.map((ele, index) => {
      if (ele.name === "descriptionSearchFilters") {
        ele.items = [{ ...DESCRIPTION_FILTER_ENTRY, value: "", isSelected: false }];
      } else {
        ele.items.map((item, itemIndex) => {
          switch (item.type) {
            case "checkbox":
              item.isSelected = false;
              break;
            case "date":
              if (item.name === "startDate") {
                // item.value = moment("2017-01-01", "YYYY-MM-DD")
                //   .subtract(1, "year")
                //   .toDate();
                item.value = undefined;
              } else {
                // item.value = moment().toDate();
                item.value = undefined;
              }
              break;
            case "date-range":
              item.value[0].startDate = undefined;
              item.value[0].endDate = undefined;
              break;
            case "tree":
              item.selectedItems = [];
              break;
            case "dropdown":
              item.selectedItems = [];
              break;
            case "number":
              item.value = "";
              break;
            case "switch":
              item.isSelected = false;
              break;
            default:
              break;
          }
          return item;
        });
      }
      return ele;
    });
    setState({ ...state, filterData });
    setExpanded([]);
    await handleOnFilterChange(filterData, "clear-filters");
  };

  const handleCheckBox = async (
    index: number,
    itemIndex: number,
    changedProperty?: string
  ) => {
    let filterItem = { ...state.filterData[index] };
    filterItem.items[itemIndex].isSelected = !filterItem.items[itemIndex]
      .isSelected;
    let filterData = [...state.filterData];
    filterData[index] = filterItem;
    setState({ ...state, filterData });
    await handleOnFilterChange(filterData, changedProperty);
  };

  const handleDateChange = async (
    newValue: any,
    index: number,
    itemIndex: number
  ) => {
    let filterItem = { ...state.filterData[index] };
    filterItem.items[itemIndex].value = moment(newValue).format("YYYY-MM-DD");
    let filterData = [...state.filterData];
    filterData[index] = filterItem;
    setState({ ...state, filterData });
    await handleOnFilterChange(filterData);
  };

  const handleDateRangeChange = async (
    newValue: any,
    index: number,
    itemIndex: number,
    isEndDate?: boolean
  ) => {
    let filterItem = { ...state.filterData[index] };
    let startDate = newValue.startDate;
    let endDate = newValue.endDate;
    if (!isEndDate) {
      let previousSelectedDate = moment(filterItem.items[itemIndex].value[0].startDate).toDate();
      if (moment(previousSelectedDate).isSame(startDate)) {
        startDate = newValue.endDate;
      }
      endDate = startDate;
    }

    filterItem.items[itemIndex].value = [{
      ...newValue,
      startDate: !isEndDate ? moment(startDate).format("YYYY-MM-DD") : filterItem?.items[itemIndex]?.value[0]?.startDate || "",
      // endDate: isStartDate?filterItem?.items[itemIndex]?.value[0]?.endDate || "":moment(newValue.endDate).format("YYYY-MM-DD"),
      // startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: isEndDate ? moment(endDate).format("YYYY-MM-DD") : moment(startDate).format("YYYY-MM-DD"),
    }];
    let filterData = [...state.filterData];
    filterData[index] = filterItem;
    setState({ ...state, filterData });
    if (!moment(startDate).isSame(endDate) || isEndDate) {
      setDateRangeAnchorEl(null);
      setDateRangeAnchorElIndex(null);
      await handleOnFilterChange(filterData);
    }
  };

  const handleChange = async (
    newValue: any,
    index: number,
    itemIndex: number
  ) => {
    let filterItem = { ...state.filterData[index] };
    filterItem.items[itemIndex].value = newValue;

    let filterData = [...state.filterData];
    filterData[index] = filterItem;
    setState({ ...state, filterData });
  };

  const handleBlur = async (name?: string) => {
    let filterData = [...state.filterData];
    if (name === "min" || name === "max") {
      let previousValue = previousFilterData
        .find((ele) => ele.name === "min-max")
        ?.items.find((ele) => ele.name === name)?.value;
      let currentValue = filterData
        .find((ele) => ele.name === "min-max")
        ?.items.find((ele) => ele.name === name)?.value;
      if (previousValue !== currentValue) {
        let minValue =
          filterData
            .find((ele) => ele.name === "min-max")
            ?.items.find((ele) => ele.name === "min")?.value || undefined;
        let maxValue =
          filterData
            .find((ele) => ele.name === "min-max")
            ?.items.find((ele) => ele.name === "max")?.value || undefined;
        if (minValue && maxValue && minValue >= maxValue) {
          await handleOnFilterChange(filterData);
        } else {
          await handleOnFilterChange(filterData);
        }
      }
    } else if (name === "minWordCount" || name === "maxWordCount") {
      let previousValue = previousFilterData
        .find((ele) => ele.name === "word-counts")
        ?.items.find((ele) => ele.name === name)?.value;
      let currentValue = filterData
        .find((ele) => ele.name === "word-counts")
        ?.items.find((ele) => ele.name === name)?.value;
      if (previousValue !== currentValue) {
        await handleOnFilterChange(filterData);
      }
    } else if (name === "amount") {
      let previousValue = previousFilterData
        .find((ele) => ele.name === "amount")
        ?.items.find((ele) => ele.name === name)?.value;
      let currentValue = filterData
        .find((ele) => ele.name === "amount")
        ?.items.find((ele) => ele.name === name)?.value;
      if (previousValue !== currentValue) {
        await handleOnFilterChange(filterData);
      }
    } else {
      await handleOnFilterChange(filterData);
    }
  };

  const onDropdownChange = async (
    newValue: any,
    index: number,
    itemIndex: number,
    isMulti: boolean,
    changedProperty?: any
  ) => {
    let filterItem = { ...state.filterData[index] };
    filterItem.items[itemIndex].selectedItems = newValue ? isMulti ? newValue : [newValue] : [];
    let filterData = [...state.filterData];
    filterData[index] = filterItem;
    setState({ ...state, filterData });
    await handleOnFilterChange(filterData, changedProperty);
  };

  const handleOnFilterChange = async (
    filterData: IFilter[],
    changedProperty?: string
  ) => {
    await onChange(filterData, changedProperty || undefined);
  };

  const search = async (input: any, callback: Function, options?: any) => {
    if (
      input === null ||
      input === undefined ||
      input === ""
    ) {
      callback(null, { options: [] });
    } else {
      let allOptions = [...options];
      callback(
        allOptions.filter((i) =>
          i.label && i.label.toLowerCase().includes(input.toLowerCase())
        )
      );
    }
  };

  const toggleFilterExpansion = (index) => {
    let filterData = [...state.filterData];
    filterData[index].isHidden = !filterData[index].isHidden;
    setState({ ...state, filterData });
  };

  const handleAddDescriptionRow = async (
    index: number,
    // changedProperty?: string
  ) => {
    let filterItem = { ...state.filterData[index] };
    filterItem.items.push({ ...DESCRIPTION_FILTER_ENTRY, value: "", isSelected: false });
    let filterData = [...state.filterData];
    filterData[index] = filterItem;
    setState({ ...state, filterData });
    await handleOnFilterChange(filterData, "add-descriptions");
  };

  const handleRemoveDescriptionRow = async (
    index: number,
    itemIndex: number,
    changedProperty?: string
  ) => {
    let filterItem = { ...state.filterData[index] };
    if (filterItem.items.length > 1) {
      filterItem.items.splice(itemIndex, 1);
    } else {
      filterItem.items[0].value = "";
    }
    let filterData = [...state.filterData];
    filterData[index] = filterItem;
    setState({ ...state, filterData });
    await handleOnFilterChange(filterData, changedProperty);
  };

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setActionAnchorEl(null);
    if (popoverAnchorEl) {
      setPopoverAnchorEl(null);
    }
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
    handleCloseActionMenu();
  }

  const handleOpenSaveFilter = (isSaveFilter) => {
    let details: ITimeFilter = JSON.parse(JSON.stringify(loadedFilter || {}))
    if (!isSaveFilter) {
      details.name = ""
      delete details.isDefault;
      delete details.id;
      delete details.userId;
      delete details.createdAt;
      delete details.modifiedAt;
    }
    delete details.filters;
    loadedFilterRef.current = details;
    handleCloseActionMenu();
    setSaveFilterDialog({
      isOpen: true,
      isSaveFilter: isSaveFilter
    })
  }

  const handleCloseSaveFilterDialog = () => {
    setSaveFilterDialog({
      isOpen: false,
      isSaveFilter: true
    })
  }

  const handleOpenLoadFilter = () => {
    handleCloseActionMenu();
    setLoadFilterDialog({
      isOpen: true
    });
  }

  const handleOpenQueryBuilder = () => {
    handleCloseActionMenu();
    setQueryBuilderDialog({
      ...queryBuilderDialog,
      isOpen: true
    });
  }

  const handleCloseQueryBuilder = (data?: { jsonData: any, sqlQuery: string }) => {
    let queryBuilderDialogObj = { ...queryBuilderDialog, isOpen: false };
    if (data) {
      queryBuilderDialogObj.jsonData = data.jsonData;
      queryBuilderDialogObj.sqlQuery = data.sqlQuery;
    }
    setQueryBuilderDialog(queryBuilderDialogObj);
  }

  const handleCloseLoadFilterDialog = async (data) => {
    if (data) {
      await onChange(data, "load-filters");
    }
    setLoadFilterDialog({
      isOpen: false
    })
  }

  const handleApplyQuery = async (data: any) => {
    setAdvancedFilterApplied(true);
    handleCloseQueryBuilder(data);
    // await onChange(data, 'query-builder');
  };

  const onRemoveSqlQuery = (isClearFilter = false) => {
    showConfirmDialog('Are you sure', 'Do you want to remove advanced filter?', async () => {
      if (isClearFilter) {
        clearFilters();
      } else {
        setAdvancedFilterApplied(false);
        setQueryBuilderDialog({ ...queryBuilderDialog, jsonData: undefined, sqlQuery: "", isOpen: false });
        // await onChange(state.filterData, "clear-filters");
      }
    });
  };

  const onClearFilterBtnClick = () => {
    isAdvancedFilterApplied
      ? onRemoveSqlQuery(true)
      : clearFilters()
  };

  const handleTreeToggle = (event: any, nodeId: string) => {
    event.preventDefault();
    let expandedItems = [...expanded];
    const index = expandedItems.findIndex((d) => d === nodeId);
    if (index !== -1) {
      expandedItems.splice(index, 1);
    } else {
      expandedItems.push(nodeId);
    }
    setExpanded(expandedItems);
  };

  const handleTreeSelect = async (event: any, nodeId: string, index: number, changedProperty: string) => {
    event.preventDefault();
    let filterItem = { ...state.filterData[index] };
    let selectedItems = filterItem.items[0].selectedItems;
    const sIndex = selectedItems.findIndex((d) => d.value === nodeId);
    if (sIndex !== -1) {
      if (changedProperty === "time-period-year") selectedItems = selectedItems.filter((d) => moment(d.value).format("YYYY") !== moment(nodeId).format("YYYY"))
      else {
        selectedItems.splice(sIndex, 1);
        const yearIndex = selectedItems.findIndex((d) => d.value === moment(nodeId).startOf("year").format(TREE_YEAR_DATE_FORMAT))
        if (yearIndex !== -1) {
          selectedItems.splice(yearIndex, 1)
        }
      }
    } else {
      if (changedProperty === "time-period-year") {
        selectedItems.push({ label: nodeId, value: nodeId });
        let momentDate = moment(nodeId);
        while (momentDate.isBefore(moment(nodeId).endOf("year"))) {
          const dateString = momentDate.format(TREE_MONTH_DATE_FORMAT);
          selectedItems.push({ value: dateString, label: dateString });
          momentDate = momentDate.add(1, "month");
        }
      } else {
        selectedItems.push({ label: nodeId, value: nodeId });
        const length = selectedItems.filter((d) => moment(d.value).format("YYYY") === moment(nodeId).format("YYYY")).length;
        if (length > 11) {
          const dateString = moment(nodeId).startOf("year").format(TREE_YEAR_DATE_FORMAT);
          selectedItems.push({ label: dateString, value: dateString })
        }
      }
    }
    filterItem.items[0].selectedItems = selectedItems;
    let filterData = [...state.filterData];
    filterData[index] = filterItem;
    setState({ ...state, filterData });
    await handleOnFilterChange(filterData, changedProperty);
  };

  const getValueAndLabel = (date: moment.Moment, isLabel = false) => {
    if (isLabel) return date.format("MMMM");
    return date.format(TREE_MONTH_DATE_FORMAT);
  };

  const getDateRangePickerStartDateMinDate = () => {
    let minDate = moment('1900/01/01', "YYYY/MM/DD").toDate();
    if (dateRangePickerMinMaxObj && dateRangePickerMinMaxObj.minDate) {
      minDate = moment(dateRangePickerMinMaxObj.minDate).toDate();
    }
    // if (item?.value?.length > 0 && item.value[0].startDate) {
    //   if (moment(item.value[0].startDate).isBefore(moment(minDate))) {
    //     minDate = new Date(item.value[0].startDate);
    //   }
    // }
    return minDate;
  }

  const getDateRangePickerMaxDate = () => {
    let maxDate = moment('2100/12/31', "YYYY/MM/DD").toDate();
    if (dateRangePickerMinMaxObj && dateRangePickerMinMaxObj.maxDate) {
      maxDate = moment(dateRangePickerMinMaxObj.maxDate).toDate();
    }
    // if (item?.value?.length > 0 && item.value[0].endDate) {
    //   if (moment(item.value[0].endDate).isAfter(moment(maxDate))) {
    //     maxDate = new Date(item.value[0].endDate);
    //   }
    // }
    return maxDate;
  }

  return (
    <React.Fragment>
      {isPopup !== true && (
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          <Typography variant="body1">
            Filters
            {loadedFilter && (
              <strong>: {loadedFilter.name}</strong>
            )}
          </Typography>
          {isFilterOpen && (
            <Button variant="outlined" onClick={() => handleFilterHide()}>
              Hide Filters
            </Button>)}
        </div>
      )}
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              {/* <Button variant="outlined" onClick={() => clearFilters()}> */}
              <Button variant="outlined" onClick={() => onClearFilterBtnClick()}>
                Clear Filters
              </Button>
            </Grid>
            {/* {typeOfFilters === "timesheets" && (
              <Grid item>
                <Button
                  id="action_menu_button"
                  aria-controls="action_menu"
                  aria-haspopup="true"
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenActionMenu}
                >
                  Actions  <ChevronDown />
                </Button>
                <Menu
                  className="actions-menu"
                  id="action_menu"
                  anchorEl={actionAnchorEl}
                  keepMounted
                  open={Boolean(actionAnchorEl)}
                  onClose={handleCloseActionMenu}
                >
                  <MenuItem onClick={() => {
                    handleOpenSaveFilter(true);
                  }}
                    disabled={isAdvancedFilterApplied || isReadOnly}
                  >
                    Save Filter
                  </MenuItem>
                  {loadedFilter && loadedFilter.id && (
                    <MenuItem onClick={() => {
                      handleOpenSaveFilter(false);
                    }}
                      disabled={isAdvancedFilterApplied || isReadOnly}
                    >
                      Save As Filter
                    </MenuItem>
                  )}
                  <MenuItem id="action_load_filter" onClick={() => {
                    handleOpenLoadFilter();
                  }}>
                    Load Filter
                  </MenuItem>
                </Menu>
              </Grid>
            )} */}
          </Grid>
        </Grid>
        {state.filterData.filter(e => e.header !== "Approved By").map((ele, index) => (
          <Grid item xs={12} key={index} className={ele.className || ""}>
            {ele.header && (
              <Grid
                container
                spacing={2}
                justify="space-between"
                className="flex-no-wrap"
              >
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h6">{ele.header}</Typography>
                    </Grid>
                    {/* {ele.name === "descriptionSearchFilters" && (
                      <Grid item>
                        <Typography
                          className="MuiLink-underlineHover pointer"
                          component="a"
                          color="primary"
                          onClick={handleOpenQueryBuilder}
                        >
                          {isAdvancedFilterApplied ? 'View Advanced Filter' : 'Advanced Filter'}
                        </Typography>
                      </Grid>
                    )} */}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2} className="flex-no-wrap">
                    {ele.name === "descriptionSearchFilters" && ele.items.length < 5 && (
                      <Grid item>
                        <span
                          className={`pointer ${isAdvancedFilterApplied && "advanced-filter-container"}`}
                          onClick={() => handleAddDescriptionRow(index)}
                        >
                          <Plus />
                        </span>
                      </Grid>
                    )}
                    <Grid item>
                      <span
                        className="pointer"
                        onClick={() => toggleFilterExpansion(index)}
                      >
                        {ele.isHidden ? <ChevronUp /> : <ChevronDown />}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!ele.isHidden && (
              <div className="d-flex flex-column">
                {ele.items.map((item, itemIndex) => (
                  <div key={itemIndex}>
                    {item.type === "checkbox" && (
                      <React.Fragment>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={item.isSelected ? true : false}
                              onChange={() =>
                                handleCheckBox(index, itemIndex, ele.name)
                              }
                              disabled={item.isDisabled ? true : false}
                            />
                          }
                          label={
                            (item.count !== undefined && ele.header !== "Approved By")
                              ? `${item.label} (${item.count || 0})`
                              : `${item.label}`
                          }
                        />
                      </React.Fragment>
                    )}
                    {item.type === "switch" && (
                      <React.Fragment>
                        <FormControlLabel
                          control={
                            <Switch size="small"
                              checked={item.isSelected ? true : false}
                              onChange={() =>
                                handleCheckBox(index, itemIndex, ele.name)
                              }
                              disabled={item.isDisabled}
                            />
                          }
                          label={
                            item.count !== undefined
                              ? `${item.label} (${item.count || 0})`
                              : `${item.label}`
                          }
                        />
                      </React.Fragment>
                    )}
                    {item.type === "date" && (
                      <React.Fragment>
                        <br />
                        {/* <TextField
                          label={item.label}
                          type="date"
                          fullWidth
                          value={moment(item.value).format("YYYY-MM-DD")}
                          onChange={(event: any) => {
                            console.log(
                              "nativeEvent",
                              event.nativeEvent.eventPhase
                            );
                            const value = event.target.value;
                            handleDateChange(value, index, itemIndex);
                          }}
                        /> */}
                        <DatePicker
                          format="MM/dd/yyyy"
                          dayPlaceholder="DD"
                          monthPlaceholder="MM"
                          yearPlaceholder="YYYY"
                          value={item.value ? moment(item.value).toDate() : undefined}
                          onChange={(newValue: any) => {
                            handleDateChange(newValue, index, itemIndex);
                          }}
                          className={item.className ? `${item.className}` : ""}
                        />
                        <br />
                      </React.Fragment>
                    )}
                    {item.type === "dropdown" && (
                      <FormControl size="small" fullWidth>
                        {/* <InputLabel className="select-label" htmlFor="bannerType">
                        {item.label}
                      </InputLabel>
                      <Select
                        value={item.value}
                        onChange={(e: any) => {
                          let value = e.target.value;
                          onDropdownChange(value, index, itemIndex);
                        }}
                      >
                        {item.options &&
                          item.options.map((x, optionsIndex) => (
                            <MenuItem
                              value={x.value}
                              key={`options${optionsIndex}`}
                            >
                              {x.label}
                            </MenuItem>
                          ))}
                      </Select> */}
                        <AsyncSelect
                          isSearchable
                          autoFocus
                          value={item?.selectedItems || []}
                          className="async-select react-select react-select-overflow-top"
                          placeholder={item.label || ""}
                          autoload={true}
                          isMulti={item.isMulti === false ? false : true}
                          isClearable={true}
                          loadOptions={debounce(
                            (input, callback) =>
                              search(input, callback, item.options),
                            500
                          )}
                          defaultOptions={item.options}
                          onChange={(newValue) =>
                            onDropdownChange(
                              newValue,
                              index,
                              itemIndex,
                              item.isMulti === false ? false : true,
                              ele.name,
                            )
                          }
                        />
                      </FormControl>
                    )}
                    {item.type === "dropdown-dynamic" && (
                      <FormControl size="small" fullWidth>
                        {item.label && (<InputLabel className="custom-react-select-label">
                          {item.label}
                        </InputLabel>
                        )}
                        <AsyncSelect
                          isSearchable
                          cacheOptions
                          value={item?.selectedItems || []}
                          className="async-select react-select react-select-overflow-top"
                          placeholder={"Search"}
                          isMulti={item.isMulti === false ? false : true}
                          isClearable={true}
                          loadOptions={item.asyncCallBackFunction}
                          defaultOptions={item.options}
                          onChange={(newValue) =>
                            onDropdownChange(
                              newValue,
                              index,
                              itemIndex,
                              item.isMulti === false ? false : true,
                              ele.name,
                            )
                          }
                        />
                      </FormControl>
                    )}
                    {item.type === "number" && (
                      <React.Fragment>
                        <br />
                        <TextField
                          label={item.label}
                          value={item.value}
                          autoFocus={false}
                          type="number"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event: any) => {
                            const value = event.target.value;
                            handleChange(
                              value ? Number(value) : "",
                              index,
                              itemIndex
                            );
                          }}
                          onBlur={(event: any) => {
                            handleBlur(item.name);
                          }}
                        />
                        <br />
                      </React.Fragment>
                    )}
                    {item.type === "text-checkbox" && (
                      <React.Fragment>
                        <br />
                        <Grid container spacing={2} direction="column">
                          {/* {isAdvancedFilterApplied && (
                            <Grid item>
                              <Card style={{ padding: '4px 8px' }}>
                                <Grid container direction="column">
                                  <Grid item>
                                    <Grid container justifyContent="flex-end">
                                      <Grid item>
                                        <IconButton size="small" onClick={() => onRemoveSqlQuery()}>
                                          <Close />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="subtitle1">{queryBuilderDialog.sqlQuery}</Typography>
                                  </Grid>
                                </Grid>
                              </Card> */}
                          {/* <Chip
                                  className="sql-query-chip"
                                  label={queryBuilderDialog.sqlQuery}
                                  variant="outlined"
                                  onDelete={onRemoveSqlQuery}
                                /> */}
                          {/* </Grid>
                          )} */}
                          <Grid item className={isAdvancedFilterApplied && 'advanced-filter-container'}>
                            <Grid container spacing={1} direction="row" alignItems="center" className="flex-no-wrap">
                              {/* {ele.items.length > 1 && ( */}
                              <Grid item>
                                <span
                                  className="pointer"
                                  onClick={() => handleRemoveDescriptionRow(index, itemIndex, ele.name)}
                                >
                                  <Close />
                                </span>
                              </Grid>
                              {/* )} */}
                              <Grid item className="flex-1">
                                <TextField
                                  // label={item.label}
                                  value={item.value}
                                  autoFocus={false}
                                  size="small"
                                  type="text"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(event: any) => {
                                    const value = event.target.value;
                                    handleChange(
                                      value,
                                      index,
                                      itemIndex
                                    );
                                  }}
                                  onBlur={(event: any) => {
                                    handleBlur(item.name);
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={item.isSelected ? true : false}
                                      onChange={() =>
                                        handleCheckBox(index, itemIndex, ele.name)
                                      }
                                    />
                                  }
                                  label={"Exclude"}
                                />
                              </Grid>
                            </Grid>
                            {/* {ele.items.length === itemIndex + 1 && <br />} */}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                    {item.type === "tree" && (
                      <React.Fragment>
                        <TreeView
                          aria-label="controlled"
                          defaultCollapseIcon={<ChevronDown />}
                          defaultExpandIcon={<ChevronRight />}
                          expanded={expanded}
                          selected={[]}
                          style={{ paddingTop: 8 }}
                        >
                          {item.options.map((d) => (
                            <StyledTreeItem key={d.value} onLabelClick={(event) => handleTreeSelect(event, d.value, index, "time-period-year")} onIconClick={(event) => handleTreeToggle(event, d.value)} nodeId={d.value} label={moment(d.label).format("YYYY")} selectedItems={item.selectedItems}>
                              {Array.from({ length: 12 }, (v, k) => moment(d.value).add(k, "months")).map((ele, eleIndex) => (
                                <StyledTreeItem key={eleIndex} onLabelClick={(event) => handleTreeSelect(event, getValueAndLabel(ele), index, "time-period-month")} nodeId={getValueAndLabel(ele)} label={getValueAndLabel(ele, true)} selectedItems={item.selectedItems} />
                              ))}
                            </StyledTreeItem>
                          ))}
                        </TreeView>
                      </React.Fragment>
                    )}
                    {item.type === "date-range" && (
                      <React.Fragment>
                        <Button variant="outlined" color="default" startIcon={<Calendar />} style={{ marginTop: 8 }} onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          setDateRangeAnchorEl(event.currentTarget);
                          setDateRangeAnchorElIndex(index)

                        }}>
                          {item.value[0].startDate && item.value[0].endDate ? `${moment(item.value[0].startDate).format("MM/DD/YYYY")} - ${moment(item.value[0].endDate).format("MM/DD/YYYY")}` : "MM/DD/YYYY - MM/DD/YYYY"}
                        </Button>
                        <Popover
                          open={dateRangeAnchorElIndex === index && Boolean(dateRangeAnchorEl)}
                          anchorEl={dateRangeAnchorEl}
                          onClose={() => {
                            setDateRangeAnchorEl(null);
                            setDateRangeAnchorElIndex(null);
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          className="date-picker-popover"
                        >
                          <Grid container direction="row">
                            <Grid item>
                              <Grid container direction="column">
                                <Grid item style={{ paddingLeft: 140, paddingTop: 6 }}>
                                  <Grid container justifyContent="center">
                                    <Typography variant="subtitle2">Start Date</Typography>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <DateRangePicker
                                    onChange={(item) => {
                                      handleDateRangeChange(item.selection, index, itemIndex);
                                    }}
                                    // showSelectionPreview={true}
                                    showPreview={false}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={item.value?.map((d: any) => ({
                                      ...d,
                                      startDate: d.startDate ? moment(d.startDate).toDate() : moment().toDate(),
                                      endDate: d.endDate ? moment(d.endDate).toDate() : moment().toDate()
                                    })) || []}
                                    direction="vertical"
                                    className="custom-date-range-picker"
                                    staticRanges={[
                                      ...defaultStaticRanges,
                                      {
                                        label: "This Year",
                                        range: () => ({
                                          startDate: startOfYear(new Date()),
                                          endDate: endOfYear(new Date())
                                        }),
                                        isSelected(range) {
                                          const definedRange = this.range();
                                          return (
                                            isSameDay(range.startDate, definedRange.startDate) &&
                                            isSameDay(range.endDate, definedRange.endDate)
                                          );
                                        }
                                      },
                                      {
                                        label: "Last Year",
                                        range: () => ({
                                          startDate: startOfYear(addYears(new Date(), -1)),
                                          endDate: endOfYear(addYears(new Date(), -1))
                                        }),
                                        isSelected(range) {
                                          const definedRange = this.range();
                                          return (
                                            isSameDay(range.startDate, definedRange.startDate) &&
                                            isSameDay(range.endDate, definedRange.endDate)
                                          );
                                        }
                                      }
                                    ]}
                                    inputRanges={[]}
                                    rangeColors={["#84adea"]}
                                    // color="#cbdcf7"
                                    minDate={getDateRangePickerStartDateMinDate()}
                                    maxDate={getDateRangePickerMaxDate()}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container direction="column">
                                <Grid item style={{ paddingTop: 6 }}>
                                  <Grid container justifyContent="center">
                                    <Typography variant="subtitle2">End Date</Typography>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <DateRange
                                    date={item?.value[0]?.endDate || ""}
                                    calendarFocus="backwards"
                                    shownDate={item?.value[0].endDate ? moment(item?.value[0]?.endDate).toDate() : undefined}
                                    showPreview={false}
                                    preventSnapRefocus
                                    onChange={(item) => {
                                      handleDateRangeChange(item.selection, index, itemIndex, true);
                                    }}
                                    minDate={item?.value && item?.value.length > 0 && item?.value[0].startDate ? moment(item?.value[0]?.startDate).toDate() : undefined}
                                    maxDate={getDateRangePickerMaxDate()}
                                    editableDateInputs={false}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={item.value?.map((d: any) => ({
                                      ...d,
                                      startDate: d.startDate ? moment(d.startDate).toDate() : moment().toDate(),
                                      endDate: d.endDate ? moment(d.endDate).toDate() : moment().toDate()
                                    })) || []}
                                    direction="vertical"
                                    className="custom-date-range"
                                    rangeColors={["#84adea"]}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Popover>
                      </React.Fragment>
                    )}
                    {item.type === "text" && (
                      <React.Fragment>
                        <br />
                        <Grid container spacing={2} direction="column">
                          <Grid item className={isAdvancedFilterApplied && 'advanced-filter-container'}>
                            <Grid container spacing={1} direction="row" alignItems="center" className="flex-no-wrap">
                              <Grid item className="flex-1">
                                <TextField
                                  value={item.value}
                                  autoFocus={false}
                                  size="small"
                                  type="text"
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(event: any) => {
                                    const value = event.target.value;
                                    handleChange(
                                      value,
                                      index,
                                      itemIndex
                                    );
                                  }}
                                  onBlur={(event: any) => {
                                    handleBlur(item.name);
                                  }}
                                />
                              </Grid>
                            </Grid>
                            {/* {ele.items.length === itemIndex + 1 && <br />} */}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                  </div>
                ))}
              </div>
            )}
          </Grid>
        ))}
      </Grid>
      <Popover
        open={Boolean(popoverAnchorEl)}
        className="load-filter-popover-container"
        anchorEl={popoverAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <section className="load-filter-popover">
          <Grid container direction="column">
            <Grid item>
              <Grid container spacing={1} justify="flex-end">
                <Grid item>
                  <Typography align="right" variant="caption">
                    <Tooltip title="Close" placement="bottom">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          handleClosePopover()
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>You can load saved filters form this action.</Typography>
            </Grid>
          </Grid>
        </section>
      </Popover>
      {queryBuilderDialog.isOpen && (
        <CustomDrawer
          title='Query Builder'
          onClose={() => handleCloseQueryBuilder()}
          handleOpen={true}
        >
          <section style={{ padding: '32px 16px 16px 16px' }}>
            <ReactQueryBuilder onApplyQuery={handleApplyQuery} data={queryBuilderDialog.jsonData} onClearQueryBuilder={onRemoveSqlQuery} />
          </section>
        </CustomDrawer>
      )}
    </React.Fragment>
  );
};

function StyledTreeItem(props) {
  const { label, nodeId, selectedItems, ...other } = props;

  return (
    <TreeItem
      label={
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={selectedItems.find((d) => d.value === nodeId) ? true : false}
            />
          }
          label={label}
          style={{ marginLeft: -4 }}
        />
        // <div style={{ display: "flex", alignItems: "center" }}>
        //   <Typography variant="body2" style={{ fontWeight: 'inherit', flexGrow: 1 }}>
        //     {label}
        //   </Typography>
        // </div>
      }
      nodeId={nodeId}
      {...other}
    />
  );
}

export default Filter;
