import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ClassProps, IToast, ILogin, IUser } from "../../vm";
import {
  getHostDetails,
  getQueryParams,
  isTokenExpired,
  setToken,
} from "../../services/UtilService";
import { withToastContext } from "../common/ToastProvider";
import {
  withStyles,
  Theme,
  createStyles,
  Paper,
  Avatar,
  Typography,
  FormControl,
  Button,
  TextField,
  Link,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { Lock } from "mdi-material-ui";
import { Formik, FormikErrors } from "formik";
import {
  getTokenForNoCase,
  loginToApp,
  loginToAppForNoCase,
  resendOTP,
  sendEmailResetPwd,
  verifyOTP,
} from "../../services/auth/AuthService";
import { withIsReadOnlyContext } from "../common/IsReadOnlyProvider";
import {
  checkIsReadOnlyApi,
  setLoginHistory,
} from "../../services/UserService";
import ManageAuthorizedCasesDialog from "./ManageAuthorizedCasesDialog";
import Loading from "../common/Loading";
import { LICENSE_AGREEMENT_URL, MAIN_APP_DOMAINS, USE_POLICY_URL, WEB_URL_PATH } from "../../Constant";
import RequestAccessMain from "../request-access/RequestAccessMain";

export interface LoginMainProps
  extends RouteComponentProps,
  ClassProps,
  IToast {
  updateStatus: Function;
  userIP: string;
}

export interface LoginMainState {
  loginCred: ILogin;
  forgotPassword: { email: string };
  isDialogOpen: boolean;
  manageAuthorizedCasesDialog: {
    isOpen: boolean;
    data?: {
      userValues: ILogin;
      caseNamesList: string[];
    };
  };
  isLoading: boolean;
  loadingMessage?: string;
  verifyCred: { otp: string };
  isOtpSent: boolean;
  requestAccessDialog: {
    isOpen: boolean;
  };
  caseNamesList: string[];
  userToken: string;
}

class LoginMain extends React.Component<LoginMainProps, LoginMainState> {
  constructor(props: LoginMainProps) {
    super(props);
    this.state = {
      loginCred: { password: "", email: "" },
      forgotPassword: { email: "" },
      isDialogOpen: false,
      manageAuthorizedCasesDialog: {
        isOpen: false,
        data: undefined,
      },
      isLoading: false,
      loadingMessage: "",
      verifyCred: { otp: "" },
      isOtpSent: false,
      requestAccessDialog: {
        isOpen: false,
      },
      caseNamesList: [],
      userToken: "",
    };
  }

  componentDidMount = () => {
    let hasTokenExpired = isTokenExpired();
    if (!hasTokenExpired) {
      this.redirectToAppropriatePage();
      // this.props.history.push("/dashboard");
    }
  };

  redirectToAppropriatePage = () => {
    const params = getQueryParams();
    if (params["redirect_to"]) {
      this.props.history.push(params["redirect_to"]);
    } else {
      this.props.history.push("/dashboard");
    }
  };

  handleManageAuthorizedCasesDialogOpen = (
    values: ILogin,
    caseNamesList: string[]
  ) => {
    this.setState({
      manageAuthorizedCasesDialog: {
        isOpen: true,
        data: { userValues: values, caseNamesList },
      },
    });
  };

  handleManageAuthorizedCasesDialogClose = async (
    selectedClassName?: string
  ) => {
    if (selectedClassName) {
      this.getTokenForSelectedCase(
        selectedClassName,
        this.state.manageAuthorizedCasesDialog.data
      );
    } else {
      this.setState({
        manageAuthorizedCasesDialog: {
          isOpen: false,
          data: undefined,
        },
      });
    }
  };

  getTokenForSelectedCase = async (
    selectedCaseName: string,
    data?: {
      userValues?: ILogin;
      caseNamesList?: string[];
    }
  ) => {
    this.setState({
      isLoading: true,
      loadingMessage: "Loading you in. Please stand by..",
    });
    if (data?.userValues?.email) {
      let tokenResult = await getTokenForNoCase(
        data?.userValues?.email,
        selectedCaseName
      );
      if (tokenResult && tokenResult.isSuccess) {
        let token = tokenResult?.data;
        if (token) {
          let useCaseRoute = `https://${selectedCaseName}.${WEB_URL_PATH}verify?token=${token}`;
          // this.props.history.replace(useCaseRoute);
          window.open(useCaseRoute, "_self");
        }
      } else {
        this.props.showToast(tokenResult.message, "error");
        this.setState({
          isLoading: false,
          loadingMessage: "",
          manageAuthorizedCasesDialog: {
            isOpen: false,
            data: undefined,
          },
        });
      }
    } else {
      this.setState({
        isLoading: false,
        loadingMessage: "",
        manageAuthorizedCasesDialog: {
          isOpen: false,
          data: undefined,
        },
      });
    }
  };

  handleOpenRequestAccessDialog = () => {
    this.setState({ requestAccessDialog: { isOpen: true } });
  };

  handleCloseRequestAccessDialog = (data?: any) => {
    this.setState({ requestAccessDialog: { isOpen: false } });
  };

  resendCode = async (email: string) => {
    if (email) {
      this.setState({ isLoading: true });
      let result = await resendOTP(email);
      if (result && result.isSuccess) {
        this.setState({ isLoading: false });
        this.props.showToast(result.message, "success");
      } else {
        this.setState({ isLoading: false });
        this.props.showToast(result.message, "error");
      }
    }
  };

  goto = (route: string) => {
    window.open(route, "_blank");
  }

  render() {
    const { classes } = this.props;
    return (
      <>
      <AppBar
          color="transparent"
          position="absolute"
          className={`${classes.appBar}`}
        >
          <Toolbar
            disableGutters={true}
            className={classes.toolbar}
            variant="dense"
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <img
                className={classes.temLogo}
                src="/tortera_logo.svg"
                alt="logo"
              />
            </Typography>
          </Toolbar>
        </AppBar>
      <main className={classes.main}>
        {this.state.isLoading && (
          <Loading message={this.state.loadingMessage || ""} />
        )}
        <Paper className={classes.paper}>
          {/* <img src="tortera_logo.svg" alt="" className={classes.logo} /> */}
          {/* <img src="logo.png" alt="" className={classes.logo} /> */}
          {/* <Avatar className={classes.avatar}>
            <Lock />
          </Avatar> */}
          {/* <Typography variant="h4">
            PlaintiffHub
          </Typography> */}
          <Typography variant="h4" component="div">
            Plaintiff<Typography variant="h4" component="span" color="primary">Hub</Typography>
          </Typography>
          <Typography variant="subtitle1">Plaintiff Management made simple</Typography>
          <br />
          {this.state.isOtpSent === false ? (
            <Formik
              initialValues={this.state.loginCred}
              validate={(values: ILogin) => {
                let errors: FormikErrors<ILogin> = {};
                if (!values.email) {
                  errors.email = "Required";
                }
                if (
                  values.email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                if (!values.password) {
                  errors.password = "Required";
                } else if (values.password.length <= 4) {
                  errors.password = "Needs to be more than 4 characters";
                }
                return errors;
              }}
              onSubmit={async (values: ILogin, { setSubmitting }) => {
                // let hostDetails = getHostDetails();
                // if (
                //   hostDetails &&
                //   hostDetails.domain &&
                //   MAIN_APP_DOMAINS.indexOf(hostDetails.subdomain) > -1
                // ) {
                //   let result = await loginToAppForNoCase(values);
                //   if (result && result.isSuccess) {
                //     this.props.showToast("Verification code sent to your email successfully", "success");
                //     let caseNamesList: string[] = (result?.data?.caseName ||
                //       []) as string[];
                //     this.setState({
                //       loginCred: values,
                //       isOtpSent: true,
                //       caseNamesList,
                //     });
                //   } else {
                //     this.props.showToast(result.message, "error");
                //   }
                // } else {
                //   // values["caseName"] = "";
                //   let result = await loginToApp(values);
                //   if (result && result.isSuccess) {
                //     setToken(result?.data);
                //     await setLoginHistory({
                //       hostAddress: this.props.userIP,
                //       type: "login"
                //     });
                //     this.props.showToast("Logged in successfully", "success");
                //     this.setState({
                //       loginCred: values,
                //       // isOtpSent: true,
                //       userToken: result?.data || "",
                //     });
                //     this.redirectToAppropriatePage();
                //   } else {
                //     this.props.showToast(result.message, "error");
                //   }
                // }
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Typography>Email : </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          size="small"
                          id="email"
                          name="email"
                          label="Email"
                          autoComplete="email"
                          autoFocus
                          onChange={handleChange}
                          error={errors.email && touched.email ? true : false}
                          helperText={
                            errors.email && touched.email && errors.email
                          }
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography>Password : </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          size="small"
                          name="password"
                          type="password"
                          label="Password"
                          autoComplete="current-password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            errors.password && touched.password ? true : false
                          }
                          helperText={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                          value={values.password}
                        />
                      </FormControl>
                      <div className="text-right">
                        <Link
                          href="#"
                          onClick={() => {
                            this.setState({ isDialogOpen: true });
                          }}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="center">
                        {/* <Grid item xs={12} sm={12} md={4}>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.submit}
                            onClick={() => this.handleOpenRequestAccessDialog()}
                          >
                            Request Access
                          </Button>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={6}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                          >
                            Log in
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" className="mb-1 login-text" component="div">
                        Please read our <Typography variant="subtitle2" color="primary" component="a" className="pointer" onClick={() => this.goto("/acceptable-use-policy")}>Acceptable Use Policy</Typography>  and the <Typography variant="subtitle2" color="primary" component="a" className="pointer" onClick={() => this.goto("/end-user-license-agreement")}>End-User License Agreement</Typography> that govern your use of PlaintiffHub as they constitute a legally binding agreement. Please note that your use of PlaintiffHub constitutes your acceptance of these terms and your agreement to be bound by them.
                      </Typography>
                      <Typography variant="subtitle2" className="mb-1 login-text" component="div">PlaintiffHub is a secure website that allows for the exchange of case information between Plaintiff Law Firms, Defendant Law Firms, Special Masters, and the Court.</Typography>
                      <Typography variant="subtitle2" className="login-text" component="div">Only persons authorized by the Case Administrator may use PlaintiffHub. If you are a member of a law firm that has not been granted access, click the Request Access button to submit a request for approval. If your law firm has been granted access to PlaintiffHub, the designated Case Administrator for your law firm can add new users through the Resource Management feature. Only authorized law firms can use PlaintiffHub.</Typography>
                    </Grid>
                  </Grid>
                  {/* <div className="text-right">
                  <Link
                    href="#"
                    onClick={() => {
                      this.setState({ showDialog: true });
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div> */}
                </form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={this.state.verifyCred}
              validate={(values: { otp: string }) => {
                let errors: FormikErrors<{ otp: string }> = {};
                if (!values.otp) {
                  errors.otp = "Required";
                }
                return errors;
              }}
              onSubmit={async (values: { otp: string }, { setSubmitting }) => {
                let otpResult = await verifyOTP(
                  values?.otp,
                  this.state.loginCred.email
                );
                if (otpResult && otpResult.isSuccess) {
                  this.props.showToast("Logged in successfully", "success");
                  let hostDetails = getHostDetails();
                  if (
                    hostDetails &&
                    hostDetails.domain &&
                    MAIN_APP_DOMAINS.indexOf(hostDetails.subdomain) > -1
                  ) {
                    let caseNamesList = this.state.caseNamesList || [];
                    if (caseNamesList.length === 0) {
                      this.props.showToast(
                        `No Cases assigned to ${this.state.loginCred?.email}`,
                        "error"
                      );
                    } else {
                      if (caseNamesList.length === 1) {
                        this.getTokenForSelectedCase(caseNamesList[0], {
                          userValues: this.state.loginCred,
                          caseNamesList,
                        });
                      } else {
                        this.handleManageAuthorizedCasesDialogOpen(
                          this.state.loginCred,
                          caseNamesList
                        );
                      }
                    }
                  } else {
                    let token = this.state.userToken || "";
                    if (token) {
                      setToken(token);
                      console.log(this.props.userIP);
                      // update is read only provider value
                      const readOnlyResult = await checkIsReadOnlyApi();
                      await setLoginHistory({
                        hostAddress: this.props.userIP,
                        type: "login",
                      });
                      if (readOnlyResult?.isSuccess) {
                        const isReadOnly = readOnlyResult.data.isReadonly;
                        this.props.updateStatus(isReadOnly);
                      }
                      this.redirectToAppropriatePage();
                      // this.props.history.push("/dashboard");
                    }
                  }
                } else {
                  this.props.showToast(otpResult.message, "error");
                }
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={12}>
                      <Typography>
                        Enter the code sent to your email:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          size="small"
                          id="otp"
                          name="otp"
                          label="Code"
                          autoComplete="otp"
                          onChange={handleChange}
                          error={errors.otp && touched.otp ? true : false}
                          helperText={errors.otp && touched.otp && errors.otp}
                          onBlur={handleBlur}
                          value={values.otp || ""}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify="space-between">
                        <Grid item xs={12} sm={12} md={4}>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                            onClick={() => {
                              this.resendCode(this.state.loginCred.email);
                            }}
                          >
                            Resend Code
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <Button
                            fullWidth
                            variant="text"
                            className={classes.submit}
                            disabled={isSubmitting}
                            onClick={() => {
                              this.setState({ isOtpSent: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                          >
                            Verify
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          )}
        </Paper>
        {this.state.isDialogOpen && (
          <Dialog
            open={true}
            disableBackdropClick
            disableEscapeKeyDown
            onClose={() => this.setState({ isDialogOpen: false })}
          >
            <DialogTitle>Forgot Password</DialogTitle>
            <DialogContent>
              <Formik
                enableReinitialize={true}
                initialValues={this.state.forgotPassword}
                validate={(values) => {
                  let errors: FormikErrors<any> = {};
                  if (!values.email) {
                    errors.email = "Required";
                  }
                  if (
                    values.email &&
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  return errors;
                }}
                onSubmit={async (values: any, { setSubmitting }) => {
                  setSubmitting(true);
                  let obj = { ...values };
                  let result = await sendEmailResetPwd(obj.email);
                  if (result?.isSuccess) {
                    this.props.showToast(`Email sent successfully`, "success");
                    this.setState({ isDialogOpen: false });
                  } else {
                    this.props.showToast(
                      result.message || `Error while updating user password`,
                      "error"
                    );
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Typography variant="subtitle2">
                      Type your registered Email to receive an mail to reset
                      your password
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            id="email"
                            name="email"
                            label="Email"
                            onChange={handleChange}
                            error={errors.email && touched.email ? true : false}
                            helperText={
                              errors.email && touched.email && errors.email
                            }
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2} justify="flex-end">
                          <Grid item>
                            <Button
                              onClick={() =>
                                this.setState({ isDialogOpen: false })
                              }
                              color="primary"
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              {isSubmitting ? "sending Email.." : "Send Email"}
                            </Button>
                          </Grid>
                        </Grid>
                        <br />
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        )}
        {this.state.manageAuthorizedCasesDialog.isOpen && (
          <ManageAuthorizedCasesDialog
            data={this.state.manageAuthorizedCasesDialog.data}
            handleDialogClose={(data?: any) =>
              this.handleManageAuthorizedCasesDialogClose(data)
            }
          />
        )}
        {this.state.requestAccessDialog.isOpen && (
          <RequestAccessMain
            handleDialogClose={(data?: any) =>
              this.handleCloseRequestAccessDialog(data)
            }
          />
        )}
      </main>
    </ >
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      marginTop: theme.spacing(8),
      maxWidth: "750px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing() * 3
        }px`,
    },
    logo: {
      width: 206,
      // width: 120,
      margin: "16px 0",
    },
    avatar: {
      margin: theme.spacing(),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(),
    },
    submit: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    temLogo: {
      // marginTop: 4,
      marginTop: 8,
      height: 30,
      marginRight: 16,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "none",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      paddingLeft: 32, // keep right padding when drawer closed
    },
  });
export default withRouter(
  withIsReadOnlyContext(withToastContext(withStyles(styles)(LoginMain)))
);
