import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { IAttentionCaseDetails } from '../../vm';
interface CaseAttentionTableProps {
    caseEntries: IAttentionCaseDetails[];
}

const CaseAttentionTable: React.FC<CaseAttentionTableProps> = (props) => {

    return (
        <section>
            <TableContainer className={"attention-table moz-table-pb"}>
                <Table size="small" className="custom-table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>Compliant Name</StyledTableCell>
                            <StyledTableCell>Def Case Number</StyledTableCell>
                            <StyledTableCell>Def Plaintiff Name</StyledTableCell>
                            <StyledTableCell>Originating Plaintiff Counsel</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.caseEntries.length > 0 ? (
                            props.caseEntries.map((timeEntry, index) => (
                                <React.Fragment>
                                    {timeEntry.results.map((ele, detailIndex) => (
                                        <StyledTableRow key={`attention_table_${index}_${detailIndex}`} className={`${index % 2 === 0 ? 'attention_table_even' : 'attention_table_odd'}`}>
                                            {detailIndex === 0 && (
                                                <>
                                                    <StyledTableCell rowSpan={timeEntry.results.length}>{ele.attentionName}</StyledTableCell>
                                                    <StyledTableCell rowSpan={timeEntry.results.length}>{ele.attentionDescription}</StyledTableCell>
                                                </>
                                            )}
                                            <StyledTableCell>{ele.complaintName}</StyledTableCell>
                                            <StyledTableCell>{ele.defCaseNumber}</StyledTableCell>
                                            <StyledTableCell>{ele.defPlaintiffName}</StyledTableCell>
                                            <StyledTableCell>{ele.originatingPlaintiffCounsel}</StyledTableCell>

                                        </StyledTableRow>
                                    ))}
                                </React.Fragment>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={11}>
                                    No Entries found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

        </section>
    );
}

export default CaseAttentionTable;