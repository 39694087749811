import * as React from "react";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
// import { ITimeFilter } from "../../vm";

interface ICaseComplianceCardProps {
    protocolList: any[];
    type: string;
    handleCheckBoxChange: Function;
    title: string;
}

const CaseComplianceCard: React.FC<ICaseComplianceCardProps> = ({ protocolList,  type, handleCheckBoxChange, title }) => {
    
    return (
        <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} style={{ padding: 16, marginTop: 16 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                        <Grid item>
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={1}>
                        {protocolList.map((d, index) => (
                            <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={d.isSelected}
                                            onChange={() =>
                                                handleCheckBoxChange(index, type)
                                            }
                                        />
                                    }
                                    label={d.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default CaseComplianceCard;

