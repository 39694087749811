import React from "react";
import { INote, INoteEntriesResponse } from "../../vm";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Grid
    ,
} from "@material-ui/core";
import { getNoteEntriesList } from "../../services/NoteService";
import CaseDetailTable from "../cases/CaseDetailTable";

export interface INoteEntriesDialogProps {
    data?: INote;
    handleDialogClose: any;
}

const NoteEntriesDialog: React.FC<INoteEntriesDialogProps> = ({
    data,
    handleDialogClose,
}) => {
    // state
    const [state, setState] = React.useState({
        caseEntries: []
    } as INoteEntriesResponse);
    const [isLoading, setLoading] = React.useState(false);
    const { showToast } = React.useContext(ToastContext);

    React.useEffect(() => {
        getNoteEntries();
    }, []);

    const getNoteEntries = async () => {
        if (data.id) {
            setLoading(true);
            let result = await getNoteEntriesList(data.id);
            setLoading(false);
            if (result?.isSuccess) {
                setState({
                    caseEntries: result.data
                })
            } else {
                showToast(result.message || "Error while getting firms", "error")
            }
        }
    };

    const closeAddDialog = (data?: boolean) => {
        handleDialogClose(data);
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <CustomDialogDrawer
                variant="large"
                title={`Case Entries`}
                onClose={() => closeAddDialog()}
                handleOpen={true}
            >
                <Grid container direction="column" spacing={2} className="firm-configure-dialog">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <CaseDetailTable casesList={state.caseEntries} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </CustomDialogDrawer>
        </React.Fragment>
    );
};

export default NoteEntriesDialog;
