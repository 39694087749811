import * as React from "react";
import { Typography } from "@material-ui/core";
export interface CaseHeaderProps {
  label: string;
  value: string;
}

const CaseHeader: React.FC<CaseHeaderProps> = (props) => {
  return (
    <React.Fragment>
      <div className="d-flex flex flex-column">
        <Typography variant="subtitle2">{props.label}</Typography>
        <Typography variant="h6">{props.value}</Typography>
      </div>
    </React.Fragment>
  );
};

export default CaseHeader;
