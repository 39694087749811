import React from "react";
import { Collapse, List, Tooltip } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { parseJwt, removeToken } from "../../services/UtilService";
import {
  CashMultiple,
  GoogleSpreadsheet,
  Home,
  Logout,
  Gavel,
  ViewDashboard,
  AccountCog,
  CogOutline,
  CloudUpload,
  FileCheck,
  Note,
  AccountBox,
  ChevronUp,
  ChevronDown,
  AccountLock,
  WrenchOutline,
  AccountTie,
  ClockCheck,
  BookLock,
  ChartPie,
  FormatAlignJustify,
  CurrencyUsd,
  AccountCheck,
  InformationOutline,
  ClockOutline,
  ChartTree,
  TableArrowDown,
  TablePlus,
  ClockCheckOutline,
} from "mdi-material-ui";
import { withIsReadOnlyContext } from "./IsReadOnlyProvider";
import { setLoginHistory } from "../../services/UserService";
import { withToastContext } from "./ToastProvider";
// import { IsReadOnlyContext } from "./IsReadOnlyProvider";
export interface SideMenuProps extends RouteComponentProps {
  isReadOnly: boolean;
  userIP: string;
}

export interface SideMenuState {
  userData: any;
  selectedIndex: number;
  isCaseMenuOpen: boolean;
  isCourtMenuOpen: boolean;
  isImportMenuOpen: boolean;
  isConfigurationMenuOpen: boolean;
  isAdministrationMenuOpen: boolean;
  isReportMenuOpen: boolean;
}

class SideMenu extends React.Component<SideMenuProps, SideMenuState> {

  // static contextType = IsReadOnlyContext;

  constructor(props: SideMenuProps) {
    super(props);
    this.state = {
      userData: parseJwt(),
      selectedIndex: 0,
      isCaseMenuOpen: false,
      isCourtMenuOpen: false,
      isImportMenuOpen: false,
      isConfigurationMenuOpen: false,
      isAdministrationMenuOpen: false,
      isReportMenuOpen: false
    };
  }
  componentDidMount = () => {
    this.updateSelectedMenu();
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateSelectedMenu();
    }
    if (this.props.isReadOnly !== prevProps.isReadOnly && this.props.isReadOnly === true) {
      this.setState({
        isCourtMenuOpen: false,
        isImportMenuOpen: false,
        // isAdministrationMenuOpen: false
      })
    }
  }

  updateSelectedMenu = () => {
    let selectedIndex = 0;
    let pathName = this.props.location.pathname;
    let isAdministrationMenuOpen = this.state.isAdministrationMenuOpen;
    let isTimeMenuOpen = this.state.isCaseMenuOpen;
    let isCourtMenuOpen = this.state.isCourtMenuOpen;
    let isImportMenuOpen = this.state.isImportMenuOpen;
    let isConfigurationMenuOpen = this.state.isConfigurationMenuOpen;
    let isReportMenuOpen = this.state.isReportMenuOpen;

    // if (pathName.includes('reports') && pathName.includes('details')) pathName = '/reports';

    switch (pathName) {
      case "/dashboard":
        selectedIndex = 0;
        break;
      case "/cases-summary":
        isTimeMenuOpen = true;
        selectedIndex = 1;
        break;
      case "/case-detail":
        isTimeMenuOpen = true;
        selectedIndex = 19;
        break;
      case "/timesheets-summary/timesheet":
        isTimeMenuOpen = true;
        selectedIndex = 1;
        break;
      case "/expenses":
        selectedIndex = 2;
        break;
      case "/submit-time-to-court":
        isCourtMenuOpen = true
        selectedIndex = 3;
        break;
      case "/submit-expense-to-court":
        isCourtMenuOpen = true
        selectedIndex = 4;
        break;
      case "/time-granted-notgranted":
        isCourtMenuOpen = true
        selectedIndex = 5;
        break;
      case "/expense-granted-notgranted":
        isCourtMenuOpen = true
        selectedIndex = 6;
        break;
      // case "/business-rules":
      //   selectedIndex = 4;
      //   break;
      case "/access-management":
        selectedIndex = 7;
        isAdministrationMenuOpen = true;
        break;
      case "/configure-firms":
        isImportMenuOpen = true;
        // isConfigurationMenuOpen = true;
        selectedIndex = 8;
        break;
      case "/import":
        isImportMenuOpen = true;
        selectedIndex = 9;
        break;
      case "/templates":
        isImportMenuOpen = true;
        selectedIndex = 32;
        break;
      case "/notes":
        isTimeMenuOpen = true
        selectedIndex = 10;
        break;
      case "/setup-firms":
        selectedIndex = 11;
        isConfigurationMenuOpen = true;
        break;
      case "/setup-plaintiff-details":
        selectedIndex = 12;
        isConfigurationMenuOpen = true;
        break;
      case "/time-category-management":
        selectedIndex = 13;
        isConfigurationMenuOpen = true;
        break;
      case "/time-approved-by-management":
        selectedIndex = 14;
        isConfigurationMenuOpen = true;
        break;
      case "/keyword-management":
        selectedIndex = 15;
        isConfigurationMenuOpen = true;
        break;
      case "/notifications-management":
        selectedIndex = 31;
        isConfigurationMenuOpen = true;
        break;
      case "/case-compliance":
        selectedIndex = 16;
        break;
      case "/rules":
        selectedIndex = 17;
        isAdministrationMenuOpen = true;
        break;
      // case "/reports":
      //   selectedIndex = 18;
      //   break;
      case "/timesheets-details":
        isTimeMenuOpen = true;
        selectedIndex = 19;
        break;
      case "/attention-case-details":
        isTimeMenuOpen = true;
        selectedIndex = 20;
        break;
      case "/about":
        isAdministrationMenuOpen = true;
        selectedIndex = 21;
        break;
      case "/reports/1/details":
        selectedIndex = 22;
        isReportMenuOpen = true;
        break;
      case "/reports/2/details":
        selectedIndex = 23;
        isReportMenuOpen = true;
        break;
      case "/reports/5/details":
        selectedIndex = 24;
        isReportMenuOpen = true;
        break;
      case "/reports/6/details":
        selectedIndex = 25;
        isReportMenuOpen = true;
        break;
      case "/reports/3/details":
        selectedIndex = 26;
        isReportMenuOpen = true;
        break;
      case "/reports/7/details":
        selectedIndex = 27;
        isReportMenuOpen = true;
        break;
      case "/reports/8/details":
        selectedIndex = 28;
        isReportMenuOpen = true;
        break;
      case "/reports/committee":
        selectedIndex = 30;
        isReportMenuOpen = true;
        break;
      case "/logins-history":
        selectedIndex = 29;
        isAdministrationMenuOpen = true;
        break;
      default:
        break;
    }
    this.setState({
      selectedIndex,
      isAdministrationMenuOpen,
      isCaseMenuOpen: isTimeMenuOpen,
      isCourtMenuOpen,
      isImportMenuOpen,
      isConfigurationMenuOpen,
      isReportMenuOpen
    });
  }

  handleListItemClick = (index: number) => {
    let isAdministrationMenuOpen = this.state.isAdministrationMenuOpen;
    // if (![7, 11, 12, 13, 14, 15, 17].includes(index)) {
    //   isAdministrationMenuOpen = false;
    // }
    this.setState({ selectedIndex: index, isAdministrationMenuOpen });
  };

  render() {
    const { selectedIndex } = this.state;
    // const { this.props.isReadOnly } = this.context;
    const { isReadOnly } = this.props;
    return (
      <List className="side-menu-list custom-side-menu">
        <ListItem
          button
          component={Link}
          to="/dashboard"
          selected={selectedIndex === 0}
          onClick={() => this.handleListItemClick(0)}
        >
          <Tooltip title="Home" placement="right">
            <ListItemIcon>
              <Home />
            </ListItemIcon>
          </Tooltip>
          <ListItemText className="">Home</ListItemText>
        </ListItem>
        <ListItem
          button
          onClick={() => this.setState({ isCaseMenuOpen: !this.state.isCaseMenuOpen })}
        >
          <Tooltip title="Time" placement="right">
            <ListItemIcon>
              <ClockOutline />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Cases</ListItemText>
          {this.state.isCaseMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isCaseMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/cases-summary"
              selected={selectedIndex === 1}
              onClick={() => this.handleListItemClick(1)}
            >
              <Tooltip title="Summary" placement="right">
                <ListItemIcon>
                  <GoogleSpreadsheet />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Summary</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/case-detail"
              selected={selectedIndex === 19}
              onClick={() => this.handleListItemClick(19)}
            >
              <Tooltip title="Details" placement="right">
                <ListItemIcon>
                  <FormatAlignJustify />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Details</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/attention-case-details"
              selected={selectedIndex === 20}
              disabled={isReadOnly}
              onClick={() => this.handleListItemClick(20)}
            >
              <Tooltip title="Attention" placement="right">
                <ListItemIcon>
                  <GoogleSpreadsheet />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Attention</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/notes"
              selected={selectedIndex === 10}
              onClick={() => this.handleListItemClick(10)}
            >
              <Tooltip title="Notes" placement="right">
                <ListItemIcon>
                  <Note />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Notes</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        
        <ListItem
          button
          component={Link}
          to="/case-compliance"
          selected={selectedIndex === 16}
          onClick={() => this.handleListItemClick(16)}
        >
          <Tooltip title="Case Compliance" placement="right">
            <ListItemIcon>
              <ViewDashboard />
            </ListItemIcon>
          </Tooltip>
          <ListItemText className="">Case Compliance</ListItemText>
        </ListItem>

        <ListItem
          button
          disabled={isReadOnly}
          onClick={() => this.setState({ isImportMenuOpen: !this.state.isImportMenuOpen })}
        >
          <Tooltip title="Import" placement="right">
            <ListItemIcon>
              <CloudUpload />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Import</ListItemText>
          {this.state.isImportMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isImportMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/configure-firms"
              selected={selectedIndex === 8}
              onClick={() => this.handleListItemClick(8)}
            >
              <Tooltip title="Configure Firms" placement="right">
                <ListItemIcon>
                  <CogOutline />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Configure Firms</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/import"
              selected={selectedIndex === 9}
              onClick={() => this.handleListItemClick(9)}
            >
              <Tooltip title="Import" placement="right">
                <ListItemIcon>
                  <FileCheck />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Import</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/templates"
              selected={selectedIndex === 32}
              onClick={() => this.handleListItemClick(32)}
            >
              <Tooltip title="Templates" placement="right">
                <ListItemIcon>
                  <FileCheck />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Templates</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          onClick={() => this.setState({ isReportMenuOpen: !this.state.isReportMenuOpen })}
        >
          <Tooltip title="Reporting" placement="right">
            <ListItemIcon>
              <ChartPie />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Reporting</ListItemText>
          {this.state.isReportMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isReportMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/reports/1/details"
              selected={selectedIndex === 22}
              onClick={() => this.handleListItemClick(22)}
            >
              <Tooltip title="Case Details" placement="right">
                <ListItemIcon>
                  <FormatAlignJustify />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Case Details</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/2/details"
              selected={selectedIndex === 23}
              onClick={() => this.handleListItemClick(23)}
            >
              <Tooltip title="Case Summary" placement="right">
                <ListItemIcon>
                  <GoogleSpreadsheet />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Case Summary</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/5/details"
              selected={selectedIndex === 24}
              onClick={() => this.handleListItemClick(24)}
            >
              <Tooltip title="Case Summary Crosstab" placement="right">
                <ListItemIcon>
                  <ChartTree />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Case Summary Crosstab</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/6/details"
              selected={selectedIndex === 25}
              onClick={() => this.handleListItemClick(25)}
            >
              <Tooltip title="Summary Visualization" placement="right">
                <ListItemIcon>
                  <GoogleSpreadsheet />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Summary Visualization</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/3/details"
              selected={selectedIndex === 26}
              onClick={() => this.handleListItemClick(26)}
            >
              <Tooltip title="Imported Cases" placement="right">
                <ListItemIcon>
                  <TableArrowDown />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Imported Cases</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/7/details"
              selected={selectedIndex === 27}
              onClick={() => this.handleListItemClick(27)}
            >
              <Tooltip title="Court Submit Cases" placement="right">
                <ListItemIcon>
                  <TableArrowDown />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Court Submit Cases</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          onClick={() => this.setState({ isConfigurationMenuOpen: !this.state.isConfigurationMenuOpen })}
        >
          <Tooltip title="Configuration" placement="right">
            <ListItemIcon>
              <CogOutline />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Configuration</ListItemText>
          {this.state.isConfigurationMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isConfigurationMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/setup-firms"
              selected={selectedIndex === 11}
              onClick={() => this.handleListItemClick(11)}
            >
              <Tooltip title="Setup Firms" placement="right">
                <ListItemIcon>
                  <AccountBox />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Setup Firms</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/setup-plaintiff-details"
              selected={selectedIndex === 12}
              onClick={() => this.handleListItemClick(12)}
            >
              <Tooltip title="Setup Plaintiff Details" placement="right">
                <ListItemIcon>
                  <AccountTie />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Setup Plaintiff Details</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          disabled={isReadOnly && this.state.userData.Role != 0}
          onClick={() => this.setState({ isAdministrationMenuOpen: !this.state.isAdministrationMenuOpen })}
        >
          <Tooltip title="Administration" placement="right">
            <ListItemIcon>
              <WrenchOutline />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Administration</ListItemText>
          {this.state.isAdministrationMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isAdministrationMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/access-management"
              selected={selectedIndex === 7}
              onClick={() => this.handleListItemClick(7)}
            >
              <Tooltip title="Access Management" placement="right">
                <ListItemIcon>
                  <AccountCog />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Access Management</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/about"
              selected={selectedIndex === 21}
              onClick={() => this.handleListItemClick(21)}
            >
              <Tooltip title="About" placement="right">
                <ListItemIcon>
                  <InformationOutline />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>About</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/logins-history"
              selected={selectedIndex === 29}
              onClick={() => this.handleListItemClick(29)}
            >
              <Tooltip title="Login/Logout Activity" placement="right">
                <ListItemIcon>
                  <ClockCheckOutline />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Login/Logout Activity</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={async () => {
          await setLoginHistory({
            hostAddress: this.props.userIP,
            type: "logout",
            logoutType: "manual"
          })
          removeToken();
          this.props.history.push("/");
        }}>
          <Tooltip title="Logout" placement="right">
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Logout</ListItemText>
        </ListItem>




        {/* <ListItem
          button
          component={Link}
          to="/management-dashboard"
          selected={selectedIndex === 16}
          onClick={() => this.handleListItemClick(16)}
        >
          <Tooltip title="Management Dashboard" placement="right">
            <ListItemIcon>
              <ViewDashboard />
            </ListItemIcon>
          </Tooltip>
          <ListItemText className="">Management Dashboard</ListItemText>
        </ListItem> */}





        {/* <ListItem
          button
          component={Link}
          to="/court-submissions"
          selected={selectedIndex === 3}
          onClick={() => this.handleListItemClick(3)}
        >
          <Tooltip title="Court Submissions" placement="right">
            <ListItemIcon>
              <Gavel />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Court Submissions</ListItemText>
        </ListItem> */}
        {/* <ListItem
          button
          component={Link}
          to="/business-rules"
          selected={selectedIndex === 4}
          onClick={() => this.handleListItemClick(4)}
        >
          <Tooltip title="Business Rules" placement="right">
            <ListItemIcon>
              <Handshake />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Business Rules</ListItemText>
        </ListItem> */}
        {/* <ListItem
          button
          component={Link}
          to="/access-management"
          selected={selectedIndex === 7}
          onClick={() => this.handleListItemClick(7)}
        >
          <Tooltip title="Access Management" placement="right">
            <ListItemIcon>
              <AccountCog />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Access Management</ListItemText>
        </ListItem> */}



        {/* <ListItem
          button
          component={Link}
          to="/resource-management"
          selected={selectedIndex === 11}
          onClick={() => this.handleListItemClick(11)}
        >
          <Tooltip title="Resource Management" placement="right">
            <ListItemIcon>
              <AccountBox />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Resource Management</ListItemText>
        </ListItem> */}


      </List>
    );
  }
}

export default withRouter(withToastContext(withIsReadOnlyContext(SideMenu)));
