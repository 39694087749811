import { ICaseDashboard, IStandardAPIResponse } from "../vm";
import { httpClient } from "./UtilService";


export async function getAllCasesDashboard(searchObj): Promise<IStandardAPIResponse<ICaseDashboard>> {
  try {
    let res = await httpClient<ICaseDashboard>(`cases/charts`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
