import moment from "moment";
import { ILoginHistoryResponse, IStandardAPIResponse, IUser, IUserObj } from "../vm";
import { httpClient } from "./UtilService";
import platform from "platform"

export async function getUsersList(
  searchObj: any
): Promise<IStandardAPIResponse<IUserObj>> {
  try {
    let res = await httpClient<IUserObj>(
      `accounts/users`,
      "POST",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getNoteUsers(): Promise<IStandardAPIResponse<IUser[]>> {
  try {
    let res = await httpClient<IUser[]>(
      `notes/notes-users`,
      "GET"
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function registerAUser(
  obj: IUser
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`accounts/register`, "POST", obj, false, true);
    // let res = await httpClient<IUser>(`login/register`, "POST", obj, false, true); // replaced route with login/register so to send caseName on creation
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function updateAUser(
  obj: IUser
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`accounts/users/${obj.id}`, "PATCH", obj, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function removeAUser(
  userId: number
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`accounts/users/${userId}`, "DELETE");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getUserCounts(
  searchObj: any
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`user-counts`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function checkIsReadOnlyApi(): Promise<IStandardAPIResponse<{ id: number, isReadonly: boolean }>> {
  try {
    let res = await httpClient<{ id: number, isReadonly: boolean }>(`accounts/read-only`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function setLoginHistory(data: { type: "login" | "logout", logoutType?: "auto" | "manual", hostAddress: string }): Promise<IStandardAPIResponse<{}>> {
  try {
    const browserInfo = platform.parse(navigator.userAgent);
    let browser = browserInfo.name;
    let browserVersion = browserInfo.version;
    let os = browserInfo.os?.family;
    let res = await httpClient<{ id: number, isReadonly: boolean }>(`auth/logins-history`, "PUT", {
      ...data,
      browser,
      browserVersion,
      os,
      when:moment().toISOString()
    });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getLoginHistory(data: { from: number; size: number }): Promise<IStandardAPIResponse<ILoginHistoryResponse>> {
  try {
    let res = await httpClient<ILoginHistoryResponse>(`auth/logins-history`, "POST", {
      ...data,
    });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}


export async function updateIsReadOnlyStatus(
  isReadOnly: boolean,
  userId: number
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`accounts/read-only?isReadOnly=${isReadOnly}&userId=${userId}`, "POST");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
