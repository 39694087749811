import {
    Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import * as React from "react";
import {
    ICaseDashboard,
    ICasePieData,
    IConfirmDialog,
} from "../../vm";
import Loading from "../common/Loading";

import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";

import "./cases.css"
import { casesStatusDetails, styles } from "../../Constant";
import CaseComplianceCard from "./CaseComplianceCard";
import { getAllCasesDashboard } from "../../services/DashboardService";
import { ToastContext } from "../common/ToastProvider";
import { StyledTableCell } from "../../services/UtilService";
export interface CaseComplianceProps extends IConfirmDialog { }

const CaseCompliance: React.FC<CaseComplianceProps> = ({ confirmDialog }) => {
    const { showToast } = React.useContext(ToastContext);
    const [state, setState] = React.useState({
        casesDashboardData: {}
    } as {
        casesDashboardData: ICaseDashboard
    });

    const [complianceList, setComplianceList] = React.useState({
        "case-rules": {
            name: "Case Rules",
            details: [
                { name: "Duplicate plaintiffs", isSelected: false },
                { name: "Missing plaintiff IDs", isSelected: false },
                { name: "Incorrect firm name", isSelected: false },
                { name: "Minor info missing", isSelected: false },
                { name: "Missing questionnaire info", isSelected: false },
            ]
        },
        "firm-rules": {
            name: "Firm Rules",
            details: [
                { name: "Duplicate Cases", isSelected: false },
                { name: "Incomplete plaintiff info", isSelected: false },
                { name: "Filed often deadline", isSelected: false },
                { name: "Incomplete questionnaire info", isSelected: false },
                { name: "Missing date info", isSelected: false },
            ]
        },
        "plaintiff-rules": {
            name: "Plaintiff Rules",
            details: [
                { name: "Duplicate names", isSelected: false },
                { name: "Duplicate firms", isSelected: false },
                { name: "Missing minor data", isSelected: false },
                { name: "Missing plaintiff IDs", isSelected: false },
            ]
        },
    } as any);
    const [isLoading, setLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState("");


    React.useEffect(() => {
        getTimesheetFilters();
    }, []);


    const getTimesheetFilters = async (search?) => {
        setLoading(true);
        const result = await getAllCasesDashboard({});
        if (result?.isSuccess) {
            // logic

            setState({
                ...state,
                casesDashboardData: result.data
            });
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching cases",
                "error"
            );
        }
        setLoading(false);
    };

    const handleCheckBoxChange = (index, type) => {
        let complianceLustDetails = { ...complianceList };
        complianceLustDetails[type].details[index].isSelected = !complianceLustDetails[type].details[index].isSelected
        setComplianceList(complianceLustDetails);
    }



    const classes = styles();
    return (
        <React.Fragment>
            {isLoading && <Loading message={loadingMessage} />}
            <section className="main-container-section pr-0 pb-0">
                <Grid className={`${classes.main}`}>
                    <Grid>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12} component={Paper} className="p-0">
                                <TableContainer>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell align="center"># Cases</StyledTableCell>
                                                <StyledTableCell align="center"># Firms</StyledTableCell>
                                                <StyledTableCell align="center"># Plaintiff</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {casesStatusDetails.map((caseStatus:ICasePieData,index:number) => (
                                            <TableRow key={`case_${index}`}>
                                                <StyledTableCell>{caseStatus.label}</StyledTableCell>
                                                <StyledTableCell align="center">{state.casesDashboardData?.statusByCases?.[caseStatus.countVariable]}</StyledTableCell>
                                                <StyledTableCell align="center">{state.casesDashboardData?.statusByFirm?.[caseStatus.countVariable]}</StyledTableCell>
                                                <StyledTableCell align="center">{state.casesDashboardData?.statusByPlaintiff?.[caseStatus.countVariable]}</StyledTableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            {Object.keys(complianceList).map(complianceTitle => (
                                <CaseComplianceCard type={complianceTitle} title={complianceList[complianceTitle].name} protocolList={complianceList[complianceTitle].details} handleCheckBoxChange={handleCheckBoxChange} />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        </React.Fragment>
    );
};

export default withConfirmDialogContext(CaseCompliance);
