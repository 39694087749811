import {
    Breadcrumbs,
    Grid,
    Hidden,
    IconButton,
    Link,
    TablePagination,
    Typography,
} from "@material-ui/core";
import {
    ChevronLeft,
    ChevronRight,
    ChevronDoubleLeft,
    ChevronDoubleRight,
} from "mdi-material-ui";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
    ITimeEntry,
    ITimeEntrySearch,
    IProfessionalHourStats,
    IFilter,
    IKeyValue,
    IConfirmDialog,
    ITimeFilter,
    ICase,
    ICaseSearch,
    IReactSelect
} from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import moment from "moment";
import {
    useUnload,
    removeNulls,
    onlyUnique,
    getCeilValueForTotalCount,
} from "../../services/UtilService";

import Loading from "../common/Loading";
import {
    styles,
    TIMESHEET_STATUS,
    TIME_ENTRY_KEY_WORDS,
    MAX_PAGINATION_OPTIONS,
} from "../../Constant";
import Filter from "../common/Filter";
import CustomDrawer from "../common/CustomDrawer";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import ToggleFilterSection from "../dashboard/ToggleFilter";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";
import { getAllCasesList, getCasesFiltersCount, getCompliantNames, getFirmNames, getPlaintiffNames } from "../../services/CaseService";
import CaseDetailTable from "./CaseDetailTable";
import CaseHeader from "./CaseHeader";

export interface CaseSummaryViewMainProps extends IConfirmDialog { }

const CaseSummaryViewMain: React.FC<CaseSummaryViewMainProps> = ({ confirmDialog }) => {
    const [isLoading, setLoading] = React.useState(false);
    const [isFilterOpen, setFilterOpen] = React.useState(true);
    const [loadingMessage, setLoadingMessage] = React.useState("");
    // const { timesheetId } = useParams<{ timesheetId: any }>();
    const history = useHistory();
    const { showToast } = React.useContext(ToastContext);

    const [filterData, setFilterData] = React.useState([] as IFilter[]);
    const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
    const managementDashboardId = React.useRef(undefined);

    const [actionAnchorEl, setActionAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isCountsLoaded, setCountsLoaded] = React.useState(false);
    const [filterCountsDic, setFilterCountsDic] = React.useState({});

    const [state, setState] = React.useState({
        casesList: [],
        selectedTimeEntries: [],
        unselectedTimeEntries: [],
        manageTimeEntryDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
            isBulkEdit: false
        },
        manageRuleFailedDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
        },
        viewLogsDialog: {
            isOpen: false,
            index: undefined,
            data: undefined,
        },
        previousItems: [],
        nextItems: [],
        currentItem: {},
        startDate: "",
        endDate: "",
        employeeName: "",
        firmName: "",
        hasAllSelectionChanged: false,
        isAllSelected: false,
        status: [],
        rowsList: [10],
        casesCount: 0,
        search: {
            from: 0,
            size: 10,
            showOnlyDisapproved: false,
            classificationType: [],
            complaintType: [],
            defMinor: [],
            questionnaireSaved: [],
            requestForDismissal: [],
            plaintiffNames: [],
            plaintiffCounsel: [],
            showPlaintiffNamesCorrected: false,
            showDefMinor: "",
            complaintName: "",
            caseNumber: "",
            startDismissalFilingDate: "",
            endDismissalFilingDate: "",
            statuses: [],
            startDefDismissalEnteredDate: "",
            endDefDismissalEnteredDate: "",
            startQuestionnaireServedDate: "",
            endQuestionnaireServedDate: "",
        }
    } as {
        selectedTimeEntries: number[], unselectedTimeEntries: number[], casesCount: number; rowsList: number[]; isAllSelected: boolean; hasAllSelectionChanged: boolean; casesList: ICase[]; manageTimeEntryDialog: { isOpen: boolean; data?: ITimeEntry; index?: number, isBulkEdit: boolean }; manageRuleFailedDialog: { isOpen: boolean; data?: any; index?: number }; viewLogsDialog: { isOpen: boolean; data?: any; index?: number }; previousItems: any[]; nextItems: any[]; currentItem: any; startDate: string; endDate: string; status: string[];
        search: ICaseSearch;
        employeeName: string; firmName: string;
    });
    const [manageNoteDialog, setManageNoteDialog] = React.useState({
        isOpen: false,
        timeEntryIds: [],
        isAddToNote: false,
    } as {
        isOpen: boolean;
        timeEntryIds: string[];
        isAddToNote: boolean
    });

    const fromManagementDashboard = React.useRef(false);
    const [loadedFilter, setLoadedFilter] = React.useState(undefined as ITimeFilter);
    const [manageSplitDialog, setManageSplitDialog] = React.useState({
        isOpen: false,
        index: undefined,
        data: undefined,
    });
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [lastPageSize, setLastPageSize] = React.useState(state.search.size || 10);
    const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
        minDate: undefined,
        maxDate: undefined
    } as {
        minDate?: Date;
        maxDate?: Date
    });

    React.useEffect(() => {
        const fetchTimesheet = async () => {
            setLoading(true);

            let a: any = localStorage.getItem("nextTimesheets");
            if (a) {
                let items = JSON.parse(a);
                // assigning it a variable so that while pushing from this component to timesheet summary component we can send the size as it is needed
                let timesheetsSummarySearchObj = items[0];
                localStorage.removeItem("nextTimesheets");
                let nextItems = [...items];
                nextItems.splice(0, 1);
                // await getAllTimeEntries(items[0], nextItems, []);
                // await getFirmEmployeeDetails(items[0]);
                let search: ICaseSearch = {
                    ...state.search,
                    from: timesheetsSummarySearchObj.from ? timesheetsSummarySearchObj.from : 0,
                    size: timesheetsSummarySearchObj.size ? timesheetsSummarySearchObj.size : 10,
                    showOnlyDisapproved: timesheetsSummarySearchObj.showOnlyDisapproved || "",
                    classificationType: timesheetsSummarySearchObj.classificationType || [],
                    complaintType: timesheetsSummarySearchObj.complaintType || [],
                    defMinor: timesheetsSummarySearchObj.defMinor || [],
                    questionnaireSaved: timesheetsSummarySearchObj.questionnaireSaved || "",
                    requestForDismissal: timesheetsSummarySearchObj.requestForDismissal || "",
                    startFilingDate: timesheetsSummarySearchObj.startFilingDate || "",
                    endFilingDate: timesheetsSummarySearchObj.endFilingDate || "",

                    plaintiffNames: timesheetsSummarySearchObj.plaintiffNames || [],
                    plaintiffCounsel: timesheetsSummarySearchObj.plaintiffCounsel || [],
                    showPlaintiffNamesCorrected: timesheetsSummarySearchObj.showPlaintiffNamesCorrected || false,
                    showDefMinor: timesheetsSummarySearchObj.showDefMinor || "",
                    complaintName: timesheetsSummarySearchObj.complaintName || "",
                    caseNumber: timesheetsSummarySearchObj.caseNumber || "",
                    startDismissalFilingDate: timesheetsSummarySearchObj.startDismissalFilingDate || "",
                    endDismissalFilingDate: timesheetsSummarySearchObj.endDismissalFilingDate || "",
                    statuses: timesheetsSummarySearchObj.statuses || [],
                    startDefDismissalEnteredDate: timesheetsSummarySearchObj.startDefDismissalEnteredDate || "",
                    endDefDismissalEnteredDate: timesheetsSummarySearchObj.endDefDismissalEnteredDate || "",
                    startQuestionnaireServedDate: timesheetsSummarySearchObj.startQuestionnaireServedDate || "",
                    endQuestionnaireServedDate: timesheetsSummarySearchObj.endQuestionnaireServedDate || "",
                };
                await getTimesheetFilters(search, undefined);
            } else {
                history.push("/cases-summary");
            }
        };
        fetchTimesheet();
    }, []);

    const getPlainTiffSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
        let result = await getPlaintiffNames(inputValue);
        return result.data.map(x => {
            return {
                label: x.defPlaintiffName,
                value: x.defPlaintiffName,
            }
        });
    }
    const getFirmsSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
        let result = await getFirmNames(inputValue);
        return result.data.map(x => {
            return {
                label: x,
                value: x,
            }
        });
    }
    const getCompliantSearchName = async (inputValue: string): Promise<IReactSelect[]> => {
        let result = await getCompliantNames(inputValue);
        return result.data.map(x => {
            return {
                label: x,
                value: x,
            }
        });
    }

    const getTimesheetFilters = async (
        search?: any,
        changedProperty?: any,
        resetSelectAll?: boolean
    ) => {
        let tempSearch = { ...state.search };
        if (search) {
            tempSearch = { ...state.search, ...search };
            if (changedProperty === "load-filters") {
                tempSearch = { ...search, from: 0, size: tempSearch.size };
            }
        }

        delete tempSearch.size;
        delete tempSearch.from;
        setLoading(true);

        let result = {
            isSuccess: true,
            success: true,
            message: "",
            data: {
                classificationType: [],
                complaintType: [],
                statuses: [],
                defMinor: [],
                questionnaireSaved: [],
                requestForDismissal: [],
            } as any,
        };

        if (isCountsLoaded !== false && changedProperty !== "clear-filters") {
            let filterKeys = [
                "classificationType",
                "complaintType",
                "statuses",
                "defMinor",
                "questionnaireSaved",
                "requestForDismissal"
            ];
            filterKeys.forEach(key => {
                result.data[key] = filterData.find(x => x.name === key)?.items.map(x => {
                    return { label: x.label, value: x.name }
                });
            })
        }

        if (isCountsLoaded === false || changedProperty === "clear-filters") {
            result = await getCasesFiltersCount();
        }
        setLoading(false);
        if (result?.isSuccess) {
            let filterCountDic = filterCountsDic;
            if (isCountsLoaded === false || changedProperty === "clear-filters") {
                let keyParams = {
                    classificationType: "classificationType",
                    complaintType: "complaintType",
                    statuses: "statuses",
                    defMinor: "defMinor",
                    questionnaireSaved: "questionnaireSaved",
                    requestForDismissal: "requestForDismissal",
                };
                filterCountDic = Object.keys(result.data).reduce(
                    (acc, key) => {
                        if (acc[key]) {
                            result.data[key].forEach((data) => {
                                acc[key][data[keyParams[key]]] = data;
                            });
                        }
                        return acc;
                    },
                    {
                        classificationType: [],
                        complaintType: [],
                        statuses: [],
                        defMinor: [],
                        questionnaireSaved: [],
                        requestForDismissal: []
                    }
                );
                setFilterCountsDic(filterCountDic);
            }
            let data = { ...result.data };

            let filterOrder = [
                "classificationType",
                "statuses",
                "showPlaintiffNamesCorrected",
                "plaintiffName",
                "firmName",
                "compliantName",
                "caseNumber",
                "complaintType",
                "defMinor",
                "questionnaireSaved",
                "requestForDismissal",
                "filingDate",
                "dismissalFilingDate",
                "defDismissalEnteredDate",
                "questionnaireServedDate",
            ];
            let customFilters = [
                "filingDate",
                "dismissalFilingDate",
                "defDismissalEnteredDate",
                "questionnaireServedDate",
                "plaintiffName",
                "firmName",
                "compliantName",
                "caseNumber",
            ];
            let filter: IFilter[] = [];

            filterOrder.forEach((filterType) => {
                if (customFilters.indexOf(filterType) > -1) {
                    switch (filterType) {
                        case "filingDate":
                            filter.push({
                                header: "Filing Date",
                                name: "filingDate",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "filingDate")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: "date-range",
                                        value: [
                                            {
                                                startDate: tempSearch.startFilingDate,
                                                endDate: tempSearch.endFilingDate,
                                                key: "selection",
                                            },
                                        ],
                                        label: "",
                                    },
                                ],
                            });
                            break;
                        case "dismissalFilingDate":
                            filter.push({
                                header: "Dismissal Filing Date",
                                name: "dismissalFilingDate",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "dismissalFilingDate")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: "date-range",
                                        value: [
                                            {
                                                startDate: tempSearch.startDismissalFilingDate,
                                                endDate: tempSearch.endDismissalFilingDate,
                                                key: "selection",
                                            },
                                        ],
                                        label: "",
                                    },
                                ],
                            });
                            break;
                        case "defDismissalEnteredDate":
                            filter.push({
                                header: "Def Dismissal Entered Date",
                                name: "defDismissalEnteredDate",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "defDismissalEnteredDate")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: "date-range",
                                        value: [
                                            {
                                                startDate: tempSearch.startDefDismissalEnteredDate,
                                                endDate: tempSearch.endDefDismissalEnteredDate,
                                                key: "selection",
                                            },
                                        ],
                                        label: "",
                                    },
                                ],
                            });
                            break;
                        case "questionnaireServedDate":
                            filter.push({
                                header: "Questionnaire Served Date",
                                name: "questionnaireServedDate",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "questionnaireServedDate")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        type: "date-range",
                                        value: [
                                            {
                                                startDate: tempSearch.startQuestionnaireServedDate,
                                                endDate: tempSearch.endQuestionnaireServedDate,
                                                key: "selection",
                                            },
                                        ],
                                        label: "",
                                    },
                                ],
                            });
                            break;
                        case "plaintiffName":
                            filter.push({
                                header: "",
                                name: "plaintiffName",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        label: "Plaintiff Name",
                                        type: "dropdown-dynamic",
                                        name: filterType,
                                        selectedItems: tempSearch.plaintiffNames.map(x => {
                                            return { label: x, value: x }
                                        }),
                                        options: [],
                                        asyncCallBackFunction: getPlainTiffSearchName,
                                        isMulti: true
                                    },
                                ],
                            });
                            break;
                        case "firmName":
                            filter.push({
                                header: "",
                                name: "firmName",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        label: "Firm Name",
                                        type: "dropdown-dynamic",
                                        name: filterType,
                                        selectedItems: tempSearch.plaintiffCounsel.map(x => {
                                            return { label: x, value: x }
                                        }),
                                        options: [],
                                        asyncCallBackFunction: getFirmsSearchName,
                                        isMulti: true
                                    },
                                ],
                            });
                            break;
                        case "compliantName":
                            filter.push({
                                header: "",
                                name: "compliantName",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "plaintiffName")?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        label: "Compliant Name",
                                        type: "dropdown-dynamic",
                                        name: filterType,
                                        selectedItems: tempSearch.complaintName?.length > 0 ? [{ label: tempSearch.complaintName, value: tempSearch.complaintName }] : [],
                                        options: [],
                                        asyncCallBackFunction: getCompliantSearchName,
                                        isMulti: true
                                    },
                                ],
                            });
                            break;
                            case "caseNumber":
                                filter.push({
                                    header: "Case Number",
                                    name: "caseNumber",
                                    isHidden:
                                        (filterData.length > 0 &&
                                            filterData.find((e) => e.name === "caseNumber").isHidden) ||
                                        false,
                                    items: [{
                                        type: "text",
                                        value: tempSearch.caseNumber,
                                        name: "caseNumber",
                                        label: ""
                                    }],
                                });
                                break;
                        default:
                            break;
                    }
                } else {
                    let ele = filterType;
                    let a: IFilter = { header: "", items: [], name: ele };
                    switch (ele) {
                        case "classificationType":
                            a.header = "Classification Type";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let classificationType = data["classificationType"] || [];
                            classificationType.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.classificationType?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "statuses":
                            a.header = "Status";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let statuses = data["statuses"] || [];
                            statuses.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.statuses?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "showPlaintiffNamesCorrected":
                            filter.push({
                                className: "disapproved-section",
                                header: "",
                                name: "showPlaintiffNamesCorrected",
                                isHidden:
                                    (filterData.length > 0 &&
                                        filterData.find((e) => e.name === "showPlaintiffNamesCorrected")
                                            ?.isHidden) ||
                                    false,
                                items: [
                                    {
                                        isSelected: tempSearch.showPlaintiffNamesCorrected,
                                        label: "Show Plaintiff Names Corrected",
                                        type: "switch",
                                        name: "showPlaintiffNamesCorrected"
                                    },
                                ],
                            });
                            break;
                        case "complaintType":
                            a.header = "Complaint Type";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let complaintType = data["complaintType"] || [];
                            complaintType.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.complaintType?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "defMinor":
                            a.header = "DEF Minor";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let defMinor = data["defMinor"] || [];
                            defMinor.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.defMinor?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "questionnaireSaved":
                            a.header = "Questionnaire Saved";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let questionnaireSaved = data["questionnaireSaved"] || [];
                            questionnaireSaved.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.questionnaireSaved?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "requestForDismissal":
                            a.header = "Request For Dismissal";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let requestForDismissal = data["requestForDismissal"] || [];
                            requestForDismissal.forEach((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.requestForDismissal?.includes(eds.value) || false,
                                    label: eds.label,
                                    name: eds.value,
                                    type: "checkbox",
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;
                        case "statusCounts":
                            a.header = "Status";
                            a.isHidden =
                                (filterData.length > 0 &&
                                    filterData.find((e) => e.name === ele)?.isHidden) ||
                                false;
                            let statusCounts = data["statusCounts"] || [];
                            Object.keys(TIMESHEET_STATUS).map((eds) => {
                                a.items.push({
                                    isSelected: tempSearch.statuses?.includes(eds) || false,
                                    label: TIMESHEET_STATUS[eds],
                                    name: eds,
                                    type: "checkbox",
                                    count:
                                        isCountsLoaded === false
                                            ? changedProperty !== undefined &&
                                                changedProperty === ele &&
                                                tempSearch.statuses.length > 0
                                                ? filterData
                                                    .find((ed) => ed.name === ele)
                                                    ?.items.find((ed) => ed.name === eds)?.count || 0
                                                : statusCounts.find((e) => e.status === eds)?.count || 0
                                            : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                                    isDisabled: tempSearch.showOnlyDisapproved,
                                });
                            });
                            break;

                        default:
                            a = undefined;
                            break;
                    }
                    if (a) {
                        filter.push(a);
                    }
                }
            });


            setCountsLoaded(true);
            setFilterData(filter);

            if (changedProperty !== "add-descriptions") {
                // tempSearch.from = 0;
                // tempSearch.size = 10;

                await getCasesList(
                    tempSearch,
                    resetSelectAll,
                    changedProperty,
                    filter
                );
            }
        } else {
            showToast(
                result.message || "Error while fetching cases counts",
                "error"
            );
        }
    };

    const getCasesList = async (
        search?: any,
        resetSelectAll?: boolean,
        changedProperty?: string,
        filter?: IFilter[],
        isAllSelectedFromRowsPerPage?: boolean
    ) => {
        let tempSearch = { ...state.search };
        if (search) {
            tempSearch = { ...state.search, ...search };
        }

        let searchObj = { ...tempSearch };
        setLoading(true);

        if (
            !isAllSelectedFromRowsPerPage &&
            searchObj.size === getCeilValueForTotalCount(state.casesCount)
        ) {
            searchObj.size = lastPageSize;
            tempSearch.size = lastPageSize;
        }
        const result = await getAllCasesList(searchObj);
        if (result?.isSuccess) {
            let isAllSelected = state.isAllSelected;
            let selectedTimeEntries = [...state.selectedTimeEntries];
            let unselectedTimeEntries = [...state.unselectedTimeEntries];
            let hasAllSelectionChanged = state.hasAllSelectionChanged;
            if (hasAllSelectionChanged) {
                selectedTimeEntries = [
                    ...selectedTimeEntries,
                    // ...result.data.timeEntryCases
                    //     .filter(
                    //         (x) =>
                    //             state.unselectedTimeEntries.indexOf(
                    //                 `${x.timeEntryCasesId}`
                    //             ) === -1
                    //     )
                    //     .map((x) => `${x.timeEntryCasesId}`),
                ];
            }
            if (resetSelectAll) {
                isAllSelected = false;
                hasAllSelectionChanged = false;
                selectedTimeEntries = [];
                unselectedTimeEntries = [];
            }
            let menuItems = [];
            for (let index = 10; index <= result.data.count; index = index + 10) {
                menuItems.push(index);
            }
            if (menuItems[menuItems.length - 1] < result.data.count) {
                menuItems.push(getCeilValueForTotalCount(result.data.count));
            }
            if (menuItems.length > MAX_PAGINATION_OPTIONS) {
                menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
            }

            // checking is the size is more than the data count
            if (tempSearch.size > result.data.count) {
                setLastPageSize(tempSearch.size);
                tempSearch.size = getCeilValueForTotalCount(result.data.count) || 10;
            }

            setState({
                ...state,
                casesCount: result.data.count,
                manageTimeEntryDialog: {
                    isOpen: false,
                    data: undefined,
                    index: undefined,
                    isBulkEdit: false,
                },
                casesList: [...result.data.timeEntryCases].map((ele) => {
                    if (
                        hasAllSelectionChanged &&
                        unselectedTimeEntries.indexOf(ele.timeEntryCasesId) ===
                        -1
                    ) {
                        ele.isSelected = true;
                    }
                    if (
                        selectedTimeEntries.indexOf(ele.timeEntryCasesId) > -1
                    ) {
                        ele.isSelected = true;
                    }
                    return ele;
                }),
                rowsList: [...menuItems],
                search: { ...state.search, ...tempSearch },
                isAllSelected,
                hasAllSelectionChanged,
                selectedTimeEntries: selectedTimeEntries.filter(onlyUnique),
                unselectedTimeEntries,
            });
        } else {
            showToast(
                result?.message ? result.message : "Error while fetching cases",
                "error"
            );
        }
        setLoading(false);
    };

    useUnload((e: any) => {
        e.preventDefault();
        e.returnValue = "";
    });

    // const getFirmEmployeeDetails = async (searchObj) => {
    //     setLoading(true);
    //     let tempSearch = { ...state.searchObj };
    //     if (searchObj) {
    //         tempSearch = { ...state.searchObj, ...searchObj };
    //     }
    //     let obj = {
    //         ...tempSearch,
    //         firmId: searchObj.firmId,
    //         personId: searchObj.personId,
    //         status: searchObj.status || tempSearch.status,
    //     };
    //     const result = await getFirmEmployeeListDetails(obj);
    //     if (result?.isSuccess) {
    //         setFirmEmployeeDetails(result.data);
    //     } else {
    //         showToast(
    //             result?.message
    //                 ? result.message
    //                 : "Error while fetching firm resource details",
    //             "error"
    //         );
    //     }
    // };

    const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
        if (changedProperty === "clear-filters") {
            setLoadedFilter(undefined);
            managementDashboardId.current = undefined;
        }
        if (changedProperty === "load-filters") {
            let details = JSON.parse(JSON.stringify(data));
            setLoadedFilter(details);
            await getTimesheetFilters(
                details.filters,
                changedProperty,
                true
            );
        } else {
            let search = { ...state.search };
            search.from = 0;
            let classificationType = data.find((ele) => ele.name === "classificationType")?.items || [];
            search.classificationType = classificationType
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let statuses = data.find((ele) => ele.name === "statuses")?.items || [];
            search.statuses = statuses
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let showPlaintiffNamesCorrected =
                data.find((ele) => ele.name === "showPlaintiffNamesCorrected")?.items || [];
            search.showPlaintiffNamesCorrected = showPlaintiffNamesCorrected.find(
                (ele) => ele.name === "showPlaintiffNamesCorrected"
            ).isSelected;
            let plaintiffName =
                data.find((ele) => ele.name === "plaintiffName")?.items || [];
            search.plaintiffNames = plaintiffName[0].selectedItems.map(x => x.value);
            let firmName =
                data.find((ele) => ele.name === "firmName")?.items || [];
            search.plaintiffCounsel = firmName[0].selectedItems.map(x => x.value);
            let compliantName =
                data.find((ele) => ele.name === "compliantName")?.items || [];
            search.complaintName = compliantName[0].selectedItems.length > 0 ? compliantName[0].selectedItems[0].value : "";
            let caseNumber =
                data.find((ele) => ele.name === "caseNumber")?.items || [];
            search.caseNumber = caseNumber[0].value;

            let filingDate = data.find((ele) => ele.name === "filingDate")?.items || [];
            search.startFilingDate = filingDate[0].value[0].startDate || undefined;
            search.endFilingDate = filingDate[0].value[0].endDate || undefined;

            let dismissalFilingDate = data.find((ele) => ele.name === "dismissalFilingDate")?.items || [];
            search.startDismissalFilingDate = dismissalFilingDate[0].value[0].startDate || undefined;
            search.endDismissalFilingDate = dismissalFilingDate[0].value[0].endDate || undefined;

            let defDismissalEnteredDate = data.find((ele) => ele.name === "defDismissalEnteredDate")?.items || [];
            search.startDefDismissalEnteredDate = defDismissalEnteredDate[0].value[0].startDate || undefined;
            search.endDefDismissalEnteredDate = defDismissalEnteredDate[0].value[0].endDate || undefined;

            let questionnaireServedDate = data.find((ele) => ele.name === "questionnaireServedDate")?.items || [];
            search.startQuestionnaireServedDate = questionnaireServedDate[0].value[0].startDate || undefined;
            search.endQuestionnaireServedDate = questionnaireServedDate[0].value[0].endDate || undefined;

            let complaintType = data.find((ele) => ele.name === "complaintType")?.items || [];
            search.complaintType = complaintType
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let defMinor = data.find((ele) => ele.name === "defMinor")?.items || [];
            search.defMinor = defMinor
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let questionnaireSaved = data.find((ele) => ele.name === "questionnaireSaved")?.items || [];
            search.questionnaireSaved = questionnaireSaved
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            let requestForDismissal = data.find((ele) => ele.name === "requestForDismissal")?.items || [];
            search.requestForDismissal = requestForDismissal
                .filter((ele) => ele.isSelected === true)
                .map((e) => {
                    return e.name;
                });
            await getTimesheetFilters(
                search,
                changedProperty,
                true
            );
        }
    };

    const navigateToTimeSheets = () => {
        history.push({
            pathname: `/cases-summary`,
            state: {
                ...state.search
            },
        });
    };

    const TablePaginationActions = (props: TablePaginationActionsProps) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className="d-flex">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <ChevronDoubleLeft />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ChevronRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <ChevronDoubleRight />
                </IconButton>
            </div>
        );
    };

    const onSearch = async (
        searchObj?: any,
        isAllSelectedFromRowsPerPage?: boolean
    ) => {
        let tempSearch = { ...state.search };
        if (searchObj) {
            tempSearch = { ...state.search, ...searchObj };
        }
        await getCasesList(
            tempSearch,
            undefined,
            undefined,
            undefined,
            isAllSelectedFromRowsPerPage
        );
    };

    const classes = styles();
    return (
        <React.Fragment>
            {isLoading && <Loading message={loadingMessage} />}
            <section className="p-16">
                {/* <div className="text-center">
                    <Typography variant="h5">Detailed Time Entries</Typography>
                </div>
                <br /> */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs
                            separator={<ChevronRight fontSize="small" />}
                            aria-label="breadcrumb"
                        >

                            <Link
                                color="primary"
                                className="pointer"
                                onClick={() => {
                                    navigateToTimeSheets();
                                }}
                            >
                                Cases
                            </Link>
                            <Typography color="textPrimary">Detailed Cases</Typography>
                        </Breadcrumbs>
                    </Grid>

                    <Grid item lg={12}>
                        <Grid className={`${classes.main8}`}>
                            <Hidden mdDown>
                                <Grid className={`time-entries-filters-height view-timesheet-filters ${isFilterOpen ? classes.filterOpenContainer : classes.filterCloseContainer}`}>
                                    {filterData.length > 0 && (
                                        <Filter
                                            data={[...filterData]}
                                            options={[]}
                                            onChange={(data: IFilter[], changedProperty?: string) => {
                                                onFilterChange(data, changedProperty);
                                            }}
                                            typeOfFilters="timesheets"
                                            selectedFilters={state.search}
                                            loadedFilter={loadedFilter}
                                            dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                                        />
                                    )}
                                </Grid>
                            </Hidden>
                            <ToggleFilterSection showFilterClass='toggle-filters-timesheet-entries-container' classes={classes} toggleFilter={() => setFilterOpen(!isFilterOpen)} isFilterOpen={isFilterOpen} />
                            <Grid className={`${isFilterOpen ? classes.mainOpenContainer : classes.mainCloseContainer}`}>
                                {state.casesList.length > 0 && (
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <section className="px-16">
                                                    <Grid container spacing={2}>
                                                        <Grid item className="case-header-item-case-id">
                                                            <CaseHeader
                                                                label="Case Id"
                                                                value={state.casesList[0].defCaseNumber || "N/A"}
                                                            ></CaseHeader>
                                                        </Grid>
                                                        <Grid item lg={3} md={3} sm={6} xs={12}>
                                                            <CaseHeader
                                                                label="Firm Name"
                                                                value={state.casesList[0].originatingPlaintiffCounsel || "N/A"}
                                                            ></CaseHeader>
                                                        </Grid>
                                                        <Grid item className="case-header-item">
                                                            <CaseHeader
                                                                label="Case Name"
                                                                value={state.casesList[0].complaintName || "N/A"}
                                                            ></CaseHeader>
                                                        </Grid>
                                                        <Grid item className="case-header-item">
                                                            <CaseHeader
                                                                label="Plaintiff"
                                                                value={String(state.casesCount || 0)}
                                                            ></CaseHeader>
                                                        </Grid>
                                                        <Grid item className="case-header-item">
                                                            <CaseHeader
                                                                label="Case Type"
                                                                value={state.casesList[0].complaintType || "N/A"}
                                                            ></CaseHeader>
                                                        </Grid>

                                                    </Grid>
                                                </section>
                                            </Grid>
                                        </Grid>
                                        <br />
                                    </Grid>
                                )}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <CaseDetailTable from="summary-view" casesList={state.casesList} isLoading={isLoading} />
                                    <TablePagination
                                        rowsPerPageOptions={state.rowsList}
                                        component="div"
                                        count={state.casesCount}
                                        rowsPerPage={state.search.size}
                                        page={
                                            state.search.from === 0
                                                ? 0
                                                : state.search.from / state.search.size
                                        }
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onPageChange={(event: any, newPage: number) => {
                                            let searchObj = { ...state.search };
                                            searchObj.from =
                                                newPage === 0
                                                    ? 0
                                                    : (Number(newPage) *
                                                        state.search.size)
                                            onSearch({ ...searchObj });
                                        }}
                                        onRowsPerPageChange={(event: any) => {
                                            let value = event.target.value;
                                            let search = { ...state.search };
                                            search.size = value ? Number(value) : 10;
                                            // set to a different state
                                            setRowsPerPage(search.size);
                                            search.from = 0;
                                            onSearch(search, search.size === getCeilValueForTotalCount(state.casesCount));
                                        }}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>

            {
                isFilterDialogOpen && (
                    <CustomDrawer
                        title={"Filter"}
                        onClose={() => setFilterDialogOpen(false)}
                    >
                        <section className="p-24">
                            <Filter
                                isPopup={true}
                                data={[...filterData]}
                                onChange={(data: IFilter[]) => {
                                    onFilterChange(data);
                                }}
                                typeOfFilters="timesheets"
                                selectedFilters={state.search}
                                loadedFilter={loadedFilter}
                                dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                            />
                        </section>
                    </CustomDrawer>
                )
            }

        </React.Fragment >
    );
};

export default withConfirmDialogContext(CaseSummaryViewMain);
